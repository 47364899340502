import { VuelidatePlugin }   from '@vuelidate/core';
import * as validators      from '@vuelidate/validators';
import {validators as customValidators, validatorFactories}   from '@/client/extensions/validation/index.js';



// import validations

export default {
    install (app) {
        app.mixin({
            methods: {}
        });
    //  app.use(VuelidatePlugin);
    },
}
