<template>
    <list class="breadcrumbs" :class="wrapperClass">
        <list-item>
            <router-link to="/">Home</router-link>
        </list-item>
        <list-item v-for="crumb of crumbs" :key="crumb" :crumb="crumb">
            <router-link  :to="crumb.path"
                          custom
                          v-slot="{ navigate, href, route }">
                <anchor @click="navigate"
                             type="default"
                             :showSlot="false"
                             v-if="!crumb.isLast">
                    {{crumb.label}}
                </anchor>
                <inline type="default"
                      :showSlot="false"
                      v-if="crumb.isLast">
                    {{crumb.label}}
                </inline>
            </router-link>
        </list-item>
    </list>
</template>

<script>
import BreadCrumbsMixin from '@/client/extensions/mixins/baseComponents/breadCrumbs.js'

    export default {
        mixins: [BreadCrumbsMixin],
        props: {
            size: {
                type: String,
                default: 'default',
            }
        },
        data: function () {
            return {

            };
        },
        computed : {
            wrapperClass () {
                return 'breadcrumbs--' + this.size;
            }
        },
        created () {

        }

    }
</script>

<style scoped lang="scss">

</style>
