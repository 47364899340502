<template>

    <div :class="wrapperClass">
        <!--<div class="oot__label oot__label--start" @click="modelValue = false">{{translate(offLabel)}}</div>-->
        <div :class="controlsClass" >
            <div class="oot__controls-line" @click="modelValue = ! modelValue">
                <div class="oot__controls-knob"></div>
            </div>
            <div class="oot__label oot__label" @click="modelValue = ! modelValue">{{modelValue ? translate(onLabel) : translate(offLabel)}}</div>

        </div>

        <input v-model="value" type="hidden" v-bind="inputAttrs"/>
    </div>

</template>

<script>
import ToggleInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/input/toggleInput.js'

    export default {
        mixins: [ToggleInputMixin],
        props: {
            modelValue    : {},// this doesn't work from mixin. reason - unknown
        },
        data: function () {
            return {};
        },

        computed: {

        },
        watch: {

        }


    }
</script>

<style scoped lang="scss">

    .oot {
        .oot__controls {
            display: flex;

            .oot__controls-line {
                position: relative;
                border: 1px solid var(--global-muted-background);
                background: var(--global-danger-background);
                height: calc(var(--global-margin) + 4px);
                box-shadow: 0 0 6px 0px rgba(0,0,0,0.3) inset;
                width: calc(var(--global-margin) * 2.5);
                cursor: pointer;
            }

            .oot__controls-knob {
                position: absolute;
                background: var(--global-muted-background);
                height: calc(var(--global-margin) - 4px);
                width: calc(var(--global-margin) - 4px);
                top: 3px;
                left: 3px;
                transition: background 350ms, transform 350ms cubic-bezier(1,.09,.65,.96);
                box-shadow: 0 0 3px 0px rgba(0,0,0,1);
            }

            &.uk-active {
                .oot__controls-line {
                    background: var(--global-success-background);
                }

                .oot__controls-knob {
                    transform: translateX(calc( 1.5 * var(--global-margin) - 4px));

                }
            }

            [dir=rtl] {
                .oot__controls-knob {
                    left: auto;
                    right: 2px;
                }

                &.uk-active {
                    .oot__controls-knob {
                        transform: translateX(calc( -1 * 1.5 * var(--global-margin) - 4px));
                    }
                }
            }
        }

        .oot__label {
            cursor: pointer;
            margin-inline-start: calc(var(--global-margin) * 0.25);
        }
    }
</style>
