

import IconMixin from '@/client/extensions/mixins/baseComponents/html/icon.js'
import _ from 'lodash/object';
export default
_.merge(IconMixin, {
    props: {
        haloRatio: {
            type :[Number],
            default: 0.5,
        },
        haloSize: {
            type: [Number, String],
            default: 0.3,
        },
        haloWidth: {
            type: [String, Boolean],
            default: false
        },
        halo: {
            type: Boolean,
            default: false,
        },
        haloTheme: {
            type: [String, Boolean],
            default: false,
        },
        haloRound: {
            type: Boolean,
            default: true,
        },
    },
    data : function () {
        return {
            haloThemeMap : {
                'default': 'gray-1',
                'lead' : 'gray-1',
                'lead-2' : 'gray-1',
                'alt': 'gray-1',
                'alt2': 'gray-1',
                'inverse': 'gray-1',
                'bg': 'inverse',
                'bg-4': 'inverse',
                'bg-5': 'inverse',
                'comp-lead': 'gray-1',
                'comp-lead-2': 'gray-1',
                'comp-alt': 'gray-1',
                'comp-alt-2': 'gray-1',
                'gray-1': 'gray-5',
                'gray-2': 'gray-5',
                'gray': 'inverse',
                'gray-3': 'inverse',
                'gray-4': 'gray-1',
                'gray-5': 'gray-1',
                'success': 'gray-1',
                'success-inverse': 'success',
                'warning': 'gray-1',
                'warning-inverse': 'success',
                'danger': 'gray-1',
                'danger-inverse': 'success',
                'error': 'gray-1',
                'error-inverse': 'success',
        
            }
        }
    },
    computed : {
        safeHaloSize () {
            if (this.width) {
                if (this.haloWidth) {
                    return this.haloWidth; // has width and halo width set
                } else {
                    return false; // we must use calc to do this, but we cant nest calcs. so leave the computation to the caller
                }
            }
            // we are using ratios
            return this.safeRatio * this.haloRatio;
        },
        haloHeightProperty() {
            let haloExtraSize = this.safeHaloSize; // '20px | false | 0.3
            // with set height where the haloWidth is in css units
            if (this.height && this.haloWidth) {
                return `calc(${this.height} + ${this.haloWidth}`
            }
            // compute the height based on the icon height
            // = height icon + (width of icon * the halo ratio)
            if (this.height && ! this.haloWidth ) {
                return `calc(${this.height} + (${this.width} * ${this.haloRatio})`
            }
        
            // with ratio
            let haloFinalRatioSize = this.safeRatio + haloExtraSize;
            return `${haloFinalRatioSize}em`
        },
        haloWidthProperty() {
            let haloExtraSize = this.safeHaloSize; // '20px | false | 0.3
            // with set height where the haloWidth is in css units
            if (this.width && this.haloWidth) {
                return `calc(${this.width} + ${this.haloWidth}`
            }
            // compute the height based on the icon height
            // = height icon + (width of icon * the halo ratio)
            if (this.width && ! this.haloWidth ) {
                return `calc(${this.width} + (${this.width} * ${this.haloRatio})`
            }
        
            // with ratio
            let haloFinalRatioSize = this.safeRatio + haloExtraSize;
        
            return `${haloFinalRatioSize}em`
        },
        haloFlexProperty() {
            let base =  this.haloWidthProperty;
            return `0 0 ${base}`;
        },
        haloBorderRadiusProperty() {
            return this.haloRound ? '50%' : '0';
        },
        haloBackgroundColorProperty () {
            let finalHaloTheme;
        
            if (this.haloTheme) {
                finalHaloTheme = this.haloTheme;
                return `var(--c-${finalHaloTheme}`;
            }
        
            // cmopute halo theme
            if (finalHaloTheme) {
                return `var(--c-${finalHaloTheme})`;
            }
        
            return  `var(--c-gray-1)`;
        },
        shouldPulse() {
            return this.pulse === true || this.pulse === 'true';
        }
    }
});
