<template>
    <div class="alert animate--fast" v-if=" ! closed"
         :class="alertClass"
         ref="alertWrapper">

        <!-- title slot -->
        <span class="alert__title" v-if="title || $slots['title']">
             <slot name="title" v-bind="{alert: this}">
                <span>{{translateTitleCase(title)}}</span>
             </slot>
        </span>

        <!-- close icon -->
        <icon class="alert__close-icon"
              size="1.8"
              icon="x"
              @click="close"
              v-if="showClose"></icon>

        <!-- main slot -->
        <div class="alert__content" v-if="content || $slots['default']">
            <slot name="default" v-bind="{alert: this}">
                {{safeTranslate(content)}}
            </slot>
        </div>

    </div>
</template>

<script>

import AlertMixin from '@/client/extensions/mixins/baseComponents/alert.js';


    export default {
        mixins: [AlertMixin],
        props: {
            theme: {
                type: [String, Boolean],
                default: false
            },
            animation: {
                type: [String, Boolean],
                default: 'fadeInUpSmall',
            },
            title: {
                type: [String, Boolean],
                default: false,
            },
            content: {
                type: [String, Boolean],
                default: false,
            }

        },
        data: function () {
            return {
                closed: false,
                themeTypeMap: {
                    primary: 'lead',
                    secondary: 'gray',
                    danger: 'danger',
                    warning: 'warning',
                    success: 'success'
                }
            };
        },
        emits: [
            'alert:closing',
            'alert:beforeClose',
            'alert:afterClose',
            'alert:beforeOpen',
            'alert:afterOpen',
        ],
        computed : {
            alertClass () {
                let result = [];
                if ( this.theme ) {
                    result.push('alert--'+this.theme);
                } else {
                    result.push('alert--'+this.themeTypeMap[this.type]);
                }

                if (this.animation) {
                    result.push('animate__' + this.animation);
                }

                return result;
            },
        },
        methods: {
            close () {
                this.$emit('alert:closing');
                this.$emit('alert:beforeClose');
                this.closed = true;
                this.$emit('alert:afterClose');
            },
            open () {
                this.$emit('alert:beforeOpen');
                this.closed = false;
                this.$emit('alert:afterOpen');
            }
        },
        created () {

        }
    }
</script>

<style scoped lang="scss">

</style>
