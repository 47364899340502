<template>
    <div class="repeatable-wrapper uk-card uk-background-muted uk-padding-small uk-card-body"
         v-sortable="{onUpdate:onReorder, handle: '[data-role=handle]'}">
        <div class="uk-padding uk-card uk-padding-small uk-card-default uk-card-body uk-margin-bottom"
                 v-for="(item, index) of value" :key="uniqueId+'-row-'+index" :cleanItem="getItemClean(item)">

                <div class="uk-flex" >

                    <div class="fields-grid uk-margin-right uk-width-expand">
                        <div class="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m uk-width-expand"
                             uk-grid>
                            <div v-for="(subItem, subIndex) of getItemClean(item)" :key="uniqueId+'-cell-'+index" :subItem="subItem">
                                <div>
                                    <form-input
                                            v-bind="fields[subIndex]"
                                            :name="name + '-' + index + '-' + subIndex"
                                            :validation="fields[subIndex].validation"
                                            :doValidations="true"
                                            :type="fields[subIndex].type"
                                            :id="name+index+subIndex"
                                            v-model="value[index][subIndex]"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="uk-flex row-buttons-container">

                        <form-button
                                    icon="menu"
                                    type="muted"
                                    :slim="true"
                                    data-role="handle"
                                    class="uk-margin-right-small"
                                    :showSlot="false"
                                    style="cursor: move"
                                    @click.stop.prevent="removeRow(index)"
                                ></form-button>

                                <form-button
                                        icon="close"
                                        type="danger"
                                        :slim="true"
                                        :showSlot="false"
                                        @click.stop.prevent="removeRow(index)"
                                ></form-button>

                            <!-- TODO: bug with form-button, where due to prop binding - @ will bind twice -->
                        </div>
                </div>
            </div>

        <div class="repeatable-field-footer">
            <div class="uk-flex uk-flex-row-reverse">
                <form-button
                        icon="plus"
                        type="primary"
                        @click.prevent="addRow"
                >Add another</form-button>
            </div>
        </div>
    </div>

</template>

<script>
    import RepeatableInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/input/repeatableInput.js'

    export default {
        mixins: [RepeatableInputMixin],
        setup (props) {

        },
        data() {
            return {}
        },
        computed: {

        },
        methods: {
            // custom input class implementation
            getInputClass (type) {
                let inputClass = [];

                  if (this.inputSize && typeof this.inputSize === 'string') {
                      inputClass.push('uk-form-width-' + this.inputSize);
                  }
                  // error class

                  if (this.hasError || false) {
                      inputClass.push('uk-form-danger');
                  }

                  // error fixed indicator
                  if (this.errorFixed || false) {
                      inputClass.push('uk-form-success');
                  }

                if (type === 'checkbox') {
                    inputClass.push('uk-checkbox');
                    return inputClass
                }

                if (type === 'radio') {
                    inputClass.push('uk-radio');
                    return inputClass
                }

                if (type === 'textarea') {
                    inputClass.push('uk-textarea');
                    return inputClass
                }

                // select and "input" can have size modifiers
                if (type === 'select') {
                    inputClass.push('uk-select');
                } else {
                    inputClass.push('uk-input');
                }

                if (type === 'large') {
                    inputClass.push('uk-form-large');
                }

                if (type === 'small') {
                    inputClass.push('uk-form-small');
                }

                return inputClass
            },
        },
        watch : {

        },
    }
</script>

<style scoped lang="scss">
    .row-buttons-container {
        @media screen and (min-width: 768px) {
            margin-top: calc(var(--global-margin) * 1.25);
            .uk-button {
                margin-inline-end: calc(var(--global-margin) * 0.25);
            }

            .uk-button:last-of-type {
                margin-inline-end: 0;
            }
        }

        @media screen and (max-width: 767px) {
            display: block;
            .uk-button {
                display: block;
                margin-inline-end: 0;
            }
        }
    }

</style>
