<template>
    <nav class="app-header  navbar  padding-s-vertical padding-l-horizontal bg-inverse c-bg fixed-top-strip"
         v-bind="$attrs">
        <div class="width-expand flex-between flex-middle gap-l">
            <div class="menu-icon-wrapper" style="width: 100px">
                <icon theme="bg"
                      class="cursor-pointer"
                      icon="align-justify-alt"
                      :size="2"
                      @click="isMenuOpen = true"/>
            </div>



            <div class="text-center width-expand">
                 <h2 class="margin-remove s:visible">{{ translateTitleCase('hear.mainAppTitle') }}</h2>
            </div>

            <img style="width: 100px; height: 70px;" :src="getDynamicAssetUrl('images/logos/ariel-uni.svg')"/>
        </div>
    </nav>
    <div class="spacer width-expand" style="height: 80px;" ></div>
    <transition
        name="app-main-side-nav"
        enter-active-class="animate__slideInRight animate--faster"
        leave-active-class="animate__slideOutRight animate--faster"
    >
        <div class="offcanvas-nav relative z-top-1 bg-inverse color-bg min-height-page" v-show="isMenuOpen">
            <icon class="absolute menu-close-icon cursor-pointer"
                  style="top: 20px; inset-inline-start: 20px;"
                  theme="bg"
                  icon="x"
                  :size="2"
                  @click="isMenuOpen = false"/>
            <main-menu @menu:navigated="isMenuOpen = false"></main-menu>
        </div>
    </transition>
</template>

<script>
    import MainMenu from '@/client/applications/hear/components/MainMenu.vue';
    export default {
        name: "AppHeader",
        components: {
            MainMenu : MainMenu
        },
        data () {
            return {
                isMenuOpen: false,
            }
        },
        computed : {
            isAdmin : function () {
                this.$store.getters['isAdmin'];
            }
        }
    };

</script>

<style lang="scss" scoped>
    .offcanvas-nav {
        position: fixed;
        top: 0;
        inset-inline-start: 0;
        min-width: 300px;
        padding: calc(var(--margin-max) * 2) var(--margin-l) var(--margin-l) var(--margin-l);

        .menu-close-icon {

        }
    }



</style>
