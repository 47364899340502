<template>
    <div>
        <nav class="navbar-container margin" uk-navbar>
            <div class="uk-navbar-left">

                <a class="uk-navbar-item uk-logo" href="#">Logo</a>

                <ul class="uk-navbar-nav">
                    <li>
                        <a href="#">
                            <icon class="uk-icon uk-margin-small-right" uk-icon="star"></icon>
                            Features
                        </a>


                    </li>
                    <li>
                        <router-link to="/">Home</router-link>
                    </li>
                    <li>
                        <router-link to="/about">About</router-link>
                    </li>
                </ul>
            </div>
            <div class="uk-navbar-right">
                <div class="uk-navbar-item">
                    <div class="user-widget-loading" v-if="! isloggedStatusKnown">
                        <spinner :inline="true" :text="safeTranslate('user.stateLoading')"></spinner>
                    </div>
                    <div class="user-widget" v-if="isloggedStatusKnown">
                        <router-link tag="a" v-if=" ! isLoggedIn" :to="{name: 'user-login'}">Login</router-link>
                        <a v-if=" isLoggedIn" @click="logout">Logout</a>
                    </div>

                </div>
            </div>
        </nav>
        <div class="uk-container">
            <slot></slot>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            'defaultComponent' : String
        },
        data: function () {
            return {

            };
        },
        computed: {
            isloggedStatusKnown () {
                return this.$store.getters['user/isTokenValid'] !== null;
            },
            isLoggedIn () {

                return this.$store.getters['user/isTokenValid'];
            }
        },
        methods: {
            logout() {
                this.$store.commit('user/invalidateAuth');
                this.$store.commit('user/clearProfile');
                this.$router.push('/');
            }
        },
        mounted () {

        }

    }
</script>

<style scoped lang="scss">

</style>
