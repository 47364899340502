<template>

    <div class="unit-box uk-flex" :class="wrapperClass">
        <div class="minus uk-input unit-control uk-input uk-background-secondary uk-text-inverse"
             @click="decrement()">
            <span uk-icon="minus"></span>
        </div>
        <input v-model.number="value" :value="value" v-bind="inputAttrs" class="uk-input uk-input-units-field"/>
        <div class="plus uk-input unit-control uk-background-secondary uk-text-inverse"
             @click="increment()">
            <span uk-icon="plus"></span>
        </div>
    </div>

</template>

<script>
import unitsInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/input/unitsInput.js'

    export default {
        mixins: [unitsInputMixin],
        props: {

        },
        data: function () {
            return {};
        },
        computed: {
            wrapperClass() {
                let result = [''];
                result.push(this.class.replace('uk-input', ''));
                return result;
            },
        },
        methods: {

        },


    }
</script>

<style scoped lang="scss">
    .uk-input-units-field {
        $inputWidth: calc(var(--global-margin) * 2.5);
        flex: 0 0 $inputWidth;
        width: $inputWidth;
        min-width: $inputWidth;
        text-align: center;
    }

    .unit-control{
        transition: opacity 200ms;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
</style>
