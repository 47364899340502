<template>
    <component :is="'Select2Input'" v-model="value"  ></component>
</template>

<script>

export default {
    props: {
        modelValue    : {},
        valueKey : {
            type: String,
            default: 'id',
        },
        labelKey : {
            type: String,
            default: 'title',
        }
    },
    setup: function (props) {

    },
    data: function () {
        return {

        };
    },
    emits: ['update:modelValue' ],
    computed: {
        value: {
            get() {
                if ( ! Array.isArray(this.modelValue)) {
                    return this.modelValue;
                }

                if (this.modelValue.length < 1) {
                    return this.modelValue;
                }

                // now we can do parsing
                return this.modelValue.map(item => {
                    if ( item && typeof item === 'object') {
                        return item[this.valueKey];
                    } else {
                        return item;
                    }
                });
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
    },
    methods: {},
    watch: {},



}
</script>

