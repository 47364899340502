<template>
    <div class="i18n-phone-input flex relative"  v-bind="noClassAttrs">
        <form-input type="select2" v-model="prefix"
                    :list="intelPrefixes"
                    :wrapper-margins="false"
                    :auto-input-styleing="false"
                    class="prefix-select">
        </form-input>

        <input v-model="number"
               style="flex: 100%;"
               v-bind="inputAttrs"/>
    </div>

</template>

<script>

import SimpleInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js'
import i18nPhoneNumberInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/input/i18nPhoneNumberInput.js'
    export default {
        mixins: [SimpleInputMixin, i18nPhoneNumberInputMixin],
    }
</script>

<style  lang="scss">

// NOTICE! non prefixed style! watch it!
.i18n-phone-input {
    [dir=rtl] & {
        flex-direction: row-reverse;
    }

    .dropdown-icon {
        $inset: calc(90px - var(--margin-m));
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-100%);
        inset-inline-start: $inset;
        [dir=rtl] & {
            inset-inline-start: auto;
            inset-inline-end: $inset;
        }
    }

    .prefix-select {
        appearance: none;
        border: none;
        background: var(--c-gray-1);
        direction: ltr;
        padding-inline-start: var(--margin-m);
        cursor: pointer;

            > .field__controls-wrapper {
                > .field__controls {
                    border: none;
                    border-inline-end: 1px solid var( --c-gray-2);
                    background: transparent;
                    border-radius: 0;

                    .icon {
                        inset-inline-end: 0.2em;
                    }

                    >    .field__element {
                        background-color: transparent;
                        padding-top: 0.6em;
                        padding-bottom: 0.6em;
                        padding-inline-start: 1em;
                        padding-inline-end: 0em;
                        direction: rtl;
                        text-align: left;
                    }
                }
            }

        .select2-dropdown {
            min-width: 200px;

            .filter-wrapper {

                [dir=rtl] {
                    direction: rtl;
                }
            }

            .field .field__element {
                [dir=rtl] & {
                    direction: rtl;
                }
            }

            .options-wrapper {
                direction: ltr!important;
                text-align: left!important;

                .select2-item-icon {
                    direction: ltr!important;
                    text-align: left!important;
                }
            }
        }
    }
}
</style>
