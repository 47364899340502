export default {
    props: {
        onLabel: {
            type: String,
            default : 'core.form.toggle.defaultOn'
        },
        offLabel: {
            type: String,
            default : 'core.form.toggle.defaultOff'
        },
        translateLabels: {
            type: Boolean,
            default: true,
        },
        class : {
            type: String,
            default: ''
        },
        /**
         * Where should the label be displayed
         * @values 'end', 'start', 'both', 'none'
         */
        labelPosition: {
            type: String,
            default: 'both'
        }
    },
    data: function () {
        return {};
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
                
            },
            set(value) {
                this.$emit('update:modelValue', value);
                return true;
            }
        },
        inputAttrs() {
            let attrs = {...this.$attrs};
            delete attrs.type;
            delete attrs.list;
            attrs.type = 'hidden';
            return attrs;
        },
        controlsClass () {
            let classArray = ['oot__controls'];
            
            if (this.value) {
                classArray.push('uk-active')
            }
            
            return classArray;
        },
        wrapperClass() {
            let result = ['on-off-toggle', 'oot',  'uk-flex uk-flex-center'];
            result.push(this.class.replace('uk-input', ''));
            return result;
        },
        
        finalLabelPosition () {
            if (['end', 'start', 'both', 'none'].includes(this.labelPosition)) {
                return this.labelPosition;
            }
            
            return 'both';
        },
        
    },
    watch: {
        modelValue: {
            // disallow non-boolean values
            handler: function (oldValue, newValue) {
                let targetValue = newValue;
                
                // in case the new value is nonsense, consider the old value
                if (typeof targetValue === 'undefined') {
                    targetValue = oldValue;
                }
                
                // cast the final value into boolean
                if (typeof targetValue !== 'boolean') {
                    this.$emit('update:modelValue', !!targetValue);
                }
                
            },
            immediate: true
        }
    }
    
    
}
