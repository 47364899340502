<template>
        <icon-halo
            v-bind="$attrs"
            v-if="networkQualityChecked"
            class="flex z-level-top-1 margin-s fixed"
            @click="notifyNetworkQuality"
            style="bottom: 100px; inset-inline-start: var(--margin-s); "
                    :theme="iconColor"
                    :halo-theme="haloColor"
                    :icon="icon"
            :size="1.2"
            :halo-ratio="1.0"></icon-halo>
</template>

<script>
import useNetworkQuality from '@/client/extensions/composition/useNetworkQuality.js';
export default {
    setup(props) {
        let {networkQuality, isUpdating, networkQualityChecked, updateConnectionQuality} = useNetworkQuality({
            immediate: props.immediate,
            checkInterval: props.checkInterval,
             thresholds: props.thresholds
        });

        return {networkQuality, isUpdating, networkQualityChecked, updateConnectionQuality};
    },
    props: {
       thresholds: {
           type: [Object, Boolean],
           default: false,
       },
        checkInterval: {
           type: Number,
            default: 60*1000
        },
        immediate: {
            type: Boolean,
            default: false,
        }
    },
    data   : function () {
        return {
            qualityString: 'loading...',
            isCheckingConnection : false,
        };
    },
    computed : {
      icon() {
          if (this.networkQuality.grade < 1) {
              return 'wifi-disabled';
          }

          return 'wifi';

      },
      haloColor() {
            if (this.networkQuality.grade < 1) {
                return 'danger';
            }

          if (this.networkQuality.grade < 3) {
              return 'warning';
          }

          return 'success';


      },
      iconColor() {
          return 'bg';

      },
    },
    methods: {
        notifyNetworkQuality() {
            this.$s.ui.notification('core.networkQuality.yourQuality', null, {
                    contentParams : {'quality' : this.translate(this.networkQuality.label)},
                    location: 'snackbar',
                    position: 'snackbar',
                })
        },
        async getConnectionSpeed() {
            if (utilities.isSSR()) {
                return false;
            }

            let imageAddr    = "https://via.placeholder.com/3000C/O";
            let downloadSize = 1355900 * 8;// 13055440;

            let getConnectionSpeedPromise = new Promise(async (fulfil, reject) => {
                let download    = new Image();
                let startTime   = (new Date()).getTime();
                download.src    = imageAddr + "?nnn=" + startTime;
                download.onload = () => {
                    let duration = ((new Date()).getTime() - startTime) / 1000;
                    let speedMbps = (downloadSize / duration / (1024 * 1024)).toFixed(2);
                    fulfil(speedMbps);
                }

                download.onerror = function (err, msg) {
                    fulfil(false);
                }
            });

            return await getConnectionSpeedPromise;
        },
        async updateConnectionQuality() {
            this.isCheckingConnection = true;
            let speed = await this.getConnectionSpeed();
            let levels = Object.keys(this.thresholds);
            console.log(levels);
            this.isCheckingConnection = false;
            this.qualityString = speed;
        }
    },
    mounted() {
        this.updateConnectionQuality();
    }

}
</script>

<style scoped lang="scss">

</style>
