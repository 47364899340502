export default {
 
    props: {
        icon: {
            type: String,
            default: 'shape-square',
        },
        set: {
            type: String,
            default: config.style.defaultIconSet
        },
        alt: {
            type: [String, Boolean],
            default: false
        },
        ratio: {
            type: [Number, String],
            default: 1.2
        },
        size: {
            type: [Number, String, Boolean],
            default: false
        },
        height: {
            type: [String, Boolean],
            default: false,
        },
        width: {
            type: [String, Boolean],
            default: false,
        },
        color: {
            type: [String, Boolean],
            default: false,
        },
        theme: {
            type : [String, Boolean],
            default: false,
        },
    },
    data : function () {
        return {}
    },
    computed : {
        iconSrc() {
            let seed = this.icon, path;
        
            let isDirectional = this.icon.endsWith('-inline-end') || this.icon.endsWith('-inline-start');
        
            // directional icon in RTL
            if (isDirectional && this.isLanguageRtl()) {
                seed = this.icon.replace('-inline-start', '-right')
                .replace('-inline-end', '-left');
            }
        
            // directional icon in LTR
            if (isDirectional && ! this.isLanguageRtl()) {
                seed = this.icon.replace('-inline-start', '-left')
                .replace('-inline-end', '-right');
            }
        
            path = `icons/${this.set}/${seed}.svg`;
            return this.getDynamicAssetUrl(path);
        },
        finalIconAlt () {
            let str = this.alt ? this.alt : 'core.iconAlt';
            return this.safeTranslate(str);
        },
        iconStyle() {
            let url = this.iconSrc;
            let maskString = `url(${url}) no-repeat center`;
        
            return {
                'mask' : maskString,
                '-webkit-mask': maskString
            }
        },
        finalIconMaskProperty() {
            let url = this.iconSrc;
            return `url(${url}) no-repeat center`
        },
        safeRatio () {
            if (this.size) { // prioritize the "size" property
                return Number(this.size);
            }
            //defer to the ratio porperty
            return Number(this.ratio);
        },
        heightProperty() {
            return this.height ? this.height : `${this.safeRatio}em`;
        },
        widthProperty() {
            return this.width ? this.width : `${this.safeRatio}em`;
        },
        flexProperty() {
            let base =  this.width ? this.width : `${this.safeRatio}em`;
            return `0 0 ${base}`;
        },
        colorProperty() {
            if (this.theme && this.theme === 'default') {
                return 'currentColor';
            }
        
            if (this.theme) {
                return 'var(--c-' + this.theme + ')';
            }
            return this.color ? this.color : 'currentcolor';
        },
    }
}
