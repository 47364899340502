export default {
    props: {
        minimum: {
            type: [Number, String],
            default: 1,
        },
        maximum: {
            type: [Number, String],
            default: 9999999999,
        },
        modelValue    : {},
        class : {
            type: String,
            default: ''
        },
        list: {} // prevent inheritence of list="" on element
    },
    data: function () {
        return {};
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                if (value < parseInt(this.minimum)) {
                    this.$emit('update:modelValue', parseInt(this.minimum));
                    this.value = parseInt(this.minimum);
                    return;
                }
                
                if (value > parseInt(this.maximum)) {
                    this.$emit('update:modelValue', parseInt(this.maximum));
                    this.value = parseInt(this.maximum);
                    return;
                }
                this.$emit('update:modelValue', value)
            }
        },
        inputAttrs() {
            let attrs = {...this.$attrs};
            delete attrs.list;
            return attrs;
        },
        wrapperClass() {
            let result = [''];
            result.push(this.class.replace('uk-input', ''));
            return result;
        },
    },
    methods: {
        increment() {
            this.value = +parseInt(this.value) + 1;
        },
        decrement() {
            if (this.value > Number(this.minimum)) {
                this.value = +parseInt(this.value) - 1;
            }
            
        }
    },
    watch: {
    
    }
    
}
