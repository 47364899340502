<template>
    <div class="custom-hr relative width-expand " style="height: 2px;border-radius: var(--border-sharp)" :class="hrClass " :style="hrStyle">
        <div class="custom-hr-content abs-center" style="" :class="textClass" v-if="$slots['default'] || text">
           <slot>
               {{translate(text)}}
           </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fontSize: {
            type: [String],
            default: 'l',
        },
        margin: {
          type: [String],
          default: 'xl'
        },
        text : {
            type: [String, Boolean],
            default: false,
        },
        icon : {
            type: [String, Boolean],
            default: false,
        },
        color : {
            type: [String],
            default: 'inverse',
        },
        background : {
            type: [String],
            default: 'bg',
        },
    },
    data: function () {
        return {};
    },
    computed : {
        hrBg () {
            return this.color;
        },
        textColor () {
            return this.color;
        },
        textBg () {
            return this.background;
        },
        computedHrMargin() {
            return this.margin;
        },
        hrClass() {
            return [
                'margin-'+this.computedHrMargin+'-vertical',
                'bg-'+this.hrBg,
            ]
        },
        hrStyle() {
          return {
            //  height: 'var(--font-'+this.computedFontSize+')'
          }
        },
        computedFontSize() {
            return this.fontSize;
        },
        textClass() {
            return [
                'padding-l-horizontal',
                'c-'+this.textColor,
                'text-'+this.computedFontSize,
                'bg-'+this.textBg
            ]
        }
    }

}
</script>

<style scoped lang="scss">

</style>
