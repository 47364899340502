<template>
    <button
            :disabled="disabled"
            :class="buttonClass"
            :style="buttonStyles"
            :type="buttonType"
            :onclick="buttonOnClick">

            <spinner v-if="loading"
                     overlay="absolute"
                     :overlayOpacity="0.15"
                     :inline="true"
                     uk-scrollspy="cls: uk-animation-fade;"
                     :size="spinnerSize"></spinner>
        <span class="button-inner">
            <span v-if="loading" class="height-keeper" >.</span>
            <span class="button-icon button-icon--start" :uk-icon="iconProps" v-if="icon"></span>
            <!-- @slot button content. Icon may appear before or after it-->
            <span class="button-text" v-if="showSlot"><slot>{{safeTranslate(this.text ? this.text : 'core.buttonTextDefault')}}</slot></span>
            <span class="button-icon button-icon--end" :uk-icon="iconEndProps" v-if="iconEnd"></span>
        </span>


    </button>
</template>

<script>
import FormButtonMixin from '@/client/extensions/mixins/baseComponents/form/formButton.js'

    export default {
        mixins: [FormButtonMixin],
        props : {
            theme : {
                type : [String, Boolean],
                default: false,
            }
        },
        data: function () {
            return {};
        },
        methods : {

        },

        computed: {
            buttonClass () {
                let className = (this.$.attrs.class || '').split(' ');

                className.push('uk-button');

                // implement button type that modifies appearance
                className.push('uk-button-'+this.type);

                // implement other button types
                if (['reset', 'default'].includes(this.type) && ! this.theme) {
                    className.push('uk-button-default');
                }
                // theme
                if (this.theme) {
                    className.push('uk-button-'+this.theme);
                }


                // implement button size
                if (['small','large', 'medium'].includes(this.size)) {
                    className.push('uk-button-'+this.size);
                }


                // implement button loading class
                if (this.loading) {
                    className.push('uk-button-'+'loading');
                }

                if (this.fullWidth) {
                    className.push('uk-width-1-1');
                }

                className.push(this.class);

                return className;
            },

            buttonStyles() {
              let styles = {};

              if (this.slim) {
                  styles['padding-left'] = '10px';
                  styles['padding-right'] = '10px';
              }

              return styles;
            },

            buttonType () {
                if (['submit', 'reset'].includes(this.type)) {
                    return this.type
                }

                if (['submit', 'reset'].includes(this.behaviour)) {
                    return this.behaviour
                }

                return  '';
            },

            spinnerSize () {
                if (this.size === 'large') {
                    return  1.3;
                }

                if (this.size === 'small') {
                    return  0.45;
                }

                return 0.7;
            },

            iconProps () {
                let result = `icon: ${this.icon}; `;


                if (this.iconRatio) {
                    result = result + 'ratio: ' + this.iconRatio + ';';
                }

                if ( ! this.iconRatio && this.size == 'small') {
                    result = result + 'ratio: 0.8;'
                }

                return result;
            },

            iconEndProps () {
                let result = `icon: ${this.iconEnd}; `;
                if (this.iconRatio) {
                    result = result + 'ratio: ' + this.iconRatio + ';';
                }

                if ( ! this.iconRatio && this.size == 'small') {
                    result = result + 'ratio: 0.8;'
                }

                return result;
            },
        },

    }
</script>

<style scoped lang="scss">
    .uk-button {
        position: relative;

        .button-icon--start {
            display: inline-block;
            margin-inline-end: 2px;
        }

        .button-icon--end {
            display: inline-block;
            margin-inline-start: 2px;
        }
    }

    .uk-button:not(.uk-button-small) .button-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        .height-keeper {
            width: 0;
            pointer-events:none;
            overflow:hidden;
            color: transparent
        }
    }

    .uk-button.uk-button-small {
        height: calc(var(--global-margin) * 1.5);

        .button-inner {
            height: calc(var(--global-margin) * 1.5);
        }
    }

    .uk-button.uk-button-medium {
        height: calc(var(--global-margin) * 2 );

        .button-inner {
            height: calc(var(--global-margin) * 1.5);
        }
    }

    .uk-button.uk-button-large {
        height: calc(var(--global-margin) * 2.6 + 3px);

        .button-inner {
            height: calc(var(--global-margin) * 2.6 + 3px);
        }
    }

    .uk-button.uk-font-bold {
       .button-text{
           position: relative;
           top:  calc(var(--global-margin) * -0.1);
       }
    }

    .uk-button.uk-button-loading .button-inner {
        .button-text, .button-icon {
            opacity: 0;
            color: transparent;
            pointer-events: none;
            visibility: hidden;
        }
    }

    .uk-button.uk-button-loading, .uk-button.uk-button-loading[disabled] {
        cursor: wait;
    }

    .uk-button[disabled] {
        cursor: not-allowed;
    }

    .button-text {
        white-space: nowrap;
    }
</style>
