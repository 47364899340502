export default {
    tag: 'he-IL',
    label: 'HE',
    labelFull: 'Hebrew (Israel)',
    flag: 'israel',
    messages: {
        message: {}
    },
    datetimeFormats : {
        hoursAndMinutes : {
            hour: 'numeric', minute: 'numeric'
        },
        short: {
            year: 'numeric', month: '2-digit', day: 'numeric',
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        },
        full : {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'long', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'
        }
    },
    numberFormats: {
        currency: {
            style: 'currency', currency: 'USD', notation: 'standard', currencyDisplay: 'symbol'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    useTitleCase: true,
    translateNumber (instance, value, type, local, options) {
        if (type === 'phone') {
            if (typeof value !== 'string') {
                return ''
            }
            let prefix = value.slice(0,4);
            let num    = value.slice(4);
            let numA = num.slice(0,3);
            let numB = num.slice(3,6)
            let numC = num.slice(6,9)
          
            return `(${prefix}) ${numA}-${numB}-${numC}`;
        }
        return instance.$n(value, type, local, options);
    },
    translateDate (instance, value, type, local, options = {}) {
        if ( ! options || typeof options !== 'object') {
            options = {};
        }
        if (options.ignoreTimezone) {
            let utcDate;
            if (typeof value === 'string') {
                utcDate = new Date(value);
            } else {
                utcDate = value;
            }
         
            let correctedTime = utcDate.getTime() + utcDate.getTimezoneOffset() *60000;
           
            return instance.$d(new Date(correctedTime), type);
    
        }
        return instance.$d(value, type, local, options);
    }
}
