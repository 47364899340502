export default {
 
    props: {
        icon: {
            type: String,
            default: 'home'
        },
        text: {
            type: String,
            default: ''
        },
        iconClass: {
            type: String,
            default: ''
        },
        textClass: {
            type: String,
            default: ''
        }
    }
}
