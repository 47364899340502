<template>
    <div :class="alertClass"  uk-alert ref="alertWrapper">
        <a v-if="showClose"
           class="uk-alert-close"
           @click="$emit('alert:closing')"
           uk-close
        ></a>
        <slot></slot>
    </div>
</template>

<script>

import AlertMixin from '@/client/extensions/mixins/baseComponents/alert.js';


    export default {
        mixins: [AlertMixin],
        props: {

        },
        data: function () {
            return {};
        },
        computed : {
            alertClass () {
                return ['uk-alert', 'uk-alert-'+this.type]
            },
        },
        methods: {
            close () {
                this.$s.ui.alert(this.$refs.alertWrapper).close();
            }
        },
        created () {

        }
    }
</script>

<style scoped lang="scss">

</style>
