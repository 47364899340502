<template>
    <div class="legal text-small" :class="dynamicWrapperClass">
        <p v-if="loginConditions">
            <span>{{ translate('core.legal.loginAcceptsTerms') }}</span>
            <a :href="translate('core.legal.tosLink')" target="_blank">
                {{ translate('core.legal.tos') }}
            </a>
            <span>&nbsp;{{ translate('core.legal.and') }}</span>
            <a :href="translate('core.legal.privacyPolicyLink')" target="_blank">
                {{ translate('core.legal.privacyPolicy') }}
            </a>
        </p>

        <p v-if="legalText">{{ translate('core.legal.legalText') }}</p>

        <p v-if="legalDocuments">
            <span>{{ translate('core.legal.hereAreTheTerms') }}</span>
            <a :href="translate('core.legal.tosLink')" target="_blank">
                {{ translate('core.legal.tos') }}
            </a>
            <span>&nbsp;{{ translate('core.legal.and') }}</span>
            <a :href="translate('core.legal.privacyPolicyLink')" target="_blank">
                {{ translate('core.legal.privacyPolicy') }}
            </a>
        </p>
        <div class="trademark" v-if="showTradeMark">
            <span>{{ translate('core.legal.trademark') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        legalText: false,
        legalDocuments: false,
        loginConditions: false,
        trademark: false,
        tradeMark: false, // allows both tradeMark OR trademark
        theme: {
            type: String,
            default: 'light'
        }

    },
    data: function () {
        return {

        };
    },
    computed : {
        dynamicWrapperClass (){
            return ['legal--' + this.theme];
        },
        showTradeMark () {
            return this.tradeMark || this.trademark;
        }
    }

}
</script>

<style scoped lang="scss">
    .legal--light,
    .legal--text {
        p,span,a {
            color: var(--c-text);
        }

        a {
            text-decoration: underline;
        }
    }

    .legal--dark,
    .legal--bg{
        p, span, a {
            color: var(--c-bg);
        }

        a {
            text-decoration: underline;
        }
    }
</style>
