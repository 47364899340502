import { createMetaManager } from 'vue-meta'
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";

export default {
    
    install (app) {

        let manager = createMetaManager({isSSR: utilities.isSSR()});

        app.use(manager);
    
        app.mixin({
            setup (a,b,c) {
            
            },
            updated () {

            },
            created () { // apply meta tags automatcally, if assigned
                let accessor = this[config.metaDataAccessorKey];

                if ( ! accessor) {
                    return;
                }
                
                let targetMeta = accessor();
                let i18n = useI18n();
                
                // case string - is page title
                if (typeof targetMeta === 'string') {
                    return useMeta({title: useI18n().t(targetMeta)}).meta
                }

                if (typeof targetMeta === 'function') {
                    let finalMeta = targetMeta({instance: this, useMeta, i18n});
                    return useMeta(finalMeta).meta
                }
                
                // case not object - we cant handle
                if (typeof targetMeta !== 'object') {
                    return;
                }
                
                // meta is object
                // auto translate
             
                let finalMeta = {...targetMeta};
                for (const [key, value] of Object.entries(finalMeta)) {
                    if (typeof value === 'string') {
                        finalMeta[key] = i18n.t(value);
                    }
                }
                return useMeta(finalMeta).meta;
                // case function - is to be run and provided to meta
              
             
            },
            mounted () {

            }
        })
    },

}
