<template>

        <div class="" uk-modal ref="modal" @hidden="emitClosed">
            <div class="uk-modal-dialog" :class="{'uk-margin-auto-vertical' : verticalCenter}">
                <button v-if="closeButton"
                        class="uk-modal-close-default"
                        type="button"
                        @click="$emit('modal:closed')"
                        uk-close></button>
                <div class="uk-modal-header" v-if="title">
                    <slot name="title" :modal="this">
                        <h2 class="uk-modal-title" >{{titleText}}</h2>
                    </slot>
                </div>

                <div class="uk-modal-body">
                    <slot default :modal="this">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </slot>
                 </div>

                <div class="uk-modal-footer" v-if="showFooter">
                    <slot name="footer" :modal="this">
                        <div class="footer-inner">
                            <button class="uk-button uk-button-primary"
                                    type="button"
                                    @click="okClickHandler">{{translate('core.ui.modal.okButtonText')}}</button>
                        </div>
                    </slot>
                 </div>
            </div>
        </div>

</template>

<script>
import ModalMixin from '@/client/extensions/mixins/baseComponents/modal.js'


    export default {
        mixins: [ModalMixin],
        props: {

        },
        data: function () {
            return {

            };
        },
        computed : {

        },

        methods: {
            closeModal () {
                utilities.waitUntil(()=> {return typeof this.$s == 'object'}, 10).then( (result) => {
                    this.$s.ui.modal(this.$refs.modal).hide();
                });
            },
            openModal () {
                utilities.waitUntil(()=> {return typeof this.$s == 'object'}, 10).then( (result) => {
                    this.$s.ui.modal(this.$refs.modal).show();
                });
            },
        },
        created () {

        },
        watch: {

        }
    }
</script>

<style scoped lang="scss">
    .uk-modal-footer {
        text-align: end;
    }
</style>
