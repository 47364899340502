<template>

        <Block class="skeleton"
               v-bind="$props"
               ref="outerContainer"
               :style="containerCss"
               :class="containerClass"
               v-if="! contentReady">

            <div class="skeleton-inner skeleton-inner--line"
                 v-for="n in count"
                 v-if="template === 'lines'">
                <div class="skeleton-shape  line"></div>
            </div>

            <div class="skeleton-inner skeleton-inner--line"
                 v-for="n in count"
                 v-if="template === 'thinLines'">
                <div class="skeleton-shape  line line--thin"></div>
            </div>
            <div class="skeleton-inner skeleton-inner--circle"
                 v-for="n in count"
                 v-if="template === 'circle'">
                <div class="skeleton-shape  circle" :style="circleCss"></div>
            </div>

            <div class="skeleton-inner skeleton-inner--square"
                 v-for="n in count"
                 v-if="template === 'square'">
                <div class="skeleton-shape  square" :style="squareCss"></div>
            </div>

            <div class="skeleton-inner skeleton-inner--story"
                 v-for="n in count"
                 v-if="template === 'story'">
                <div class="skeleton-story-block">
                    <div class="skeleton-shape  square" :style="squareCss"></div>
                </div>
                <div class="skeleton-story-lines">
                    <div class="skeleton-shape  line story-line-1"></div>
                    <div class="skeleton-shape  line story-line-2"></div>
                    <div class="skeleton-shape  line story-line-3"></div>
                </div>

            </div>

            <div class="skeleton-inner skeleton-inner--form"
                 v-for="n in count"
                 v-if="template === 'form'">
                <div class="skeleton-form-block">
                    <div class="skeleton-form-title line skeleton-shape"></div>
                    <div class="skeleton-form-field line skeleton-shape"></div>
                </div>
            </div>
        </Block>

        <slot v-else ></slot>


</template>

<script>
import SkeletonMixin from '@/client/extensions/mixins/baseComponents/skeleton.js'
    export default {
        mixins: [SkeletonMixin],
        props: {

        },
        data: function () {
            return {

            };
        },
        computed: {
            containerCss () {
                return {
                    height: this.height + 'px'
                }
            },
            circleCss () {
                return {
                    height: this.shapeDimension + 'px',
                    flex: this.shapeDimension + 'px',
                    width: this.shapeDimension + 'px',
                }
            },
            squareCss () {
                return {
                    height: this.shapeDimension + 'px',
                    flex: this.shapeDimension + 'px',
                    width: this.shapeDimension + 'px',
                }
            },
            containerClass () {
                let containerClass = this.class.split();

                containerClass.push('skeleton-wrapper')

                if (this.inverse) {
                    containerClass.push('skeleton-wrapper--inverse');
                }

                return containerClass;
            }

        },
        mounted () {
            this.mounted = true;
        }

    }
</script>

<style scoped lang="scss">
    .skeleton-shape {
        display: flex;
        background: var(--c-gray-2);
        background-image: linear-gradient(to right, var(--c-gray-2) 0%, var(--c-bg) 20%, var(--c-gray-2) 40%, var(--c-gray-2) 100%);
        background-repeat: no-repeat;
        background-size: 200% 100%;
        position: relative;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: linear;
    }

    .skeleton-wrapper--inverse {
        .skeleton-shape {
            background: var(--c-inverse);
            background-image: linear-gradient(to right, var(--c-bg) 0%, var(--c-inverse) 20%, var(--c-bg) 40%, var(--c-bg) 100%);
            background-repeat: no-repeat;
            background-size: 200% 100%;
            position: relative;
            -webkit-animation-duration: 1s;
            -webkit-animation-fill-mode: forwards;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-name: placeholderShimmer;
            -webkit-animation-timing-function: linear;
        }
    }

    .line {
        flex: 100%;
        height: calc(var(--margin) * 2.75);
        margin-bottom: calc(var(--margin) * 2);
    }

    .line.line--thin {
        height: calc(var(--margin) * 1);
        margin-bottom: calc(var(--margin) * 0.75);
    }


    .circle {
        display: flex;
        border-radius: 50%;
    }

    .skeleton-inner.skeleton-inner--story {
        padding: 20px;
        display: flex;

        .skeleton-story-block {
            flex: 70px;
            margin-inline-end: var(--margin);
        }

        .skeleton-story-lines {
            display: block;
            flex: 100%;
            width: 100%;
            flex-wrap: wrap;

            .line {
                flex: 100%;
                width: 100%;
                height: calc(var(--margin) * 1);
                margin-bottom: calc(var(--margin) * 0.7);
            }

            .story-line-1 {
                width: 70%;
            }

            .story-line-2 {
                width: 80%;
            }

            .story-line-3 {
                width: 50%;
            }
        }
    }

    .skeleton-inner--form  {
        display: block;

        .skeleton-form-block  {
            display: block;

            .line {
                display: block;
            }

            .skeleton-form-title  {
                height: 14px;
                margin-bottom: 10px;
                width: 30%;
            }

            .skeleton-form-field {
                height: 40px;
                margin-bottom: 20px;
                width: 100%;
            }
        }
    }

    @-webkit-keyframes placeholderShimmer {
        0% {background-position: 150% 0;}
        100% {background-position: -150% 0;}
    }
</style>
