<template>
    <div :class="wrapperClass"
         :style="wrapperStyle"
         :id="id"
         v-if="inputSubType != 'hidden'">

        <label  :for="fieldId"
                :class="labelClass"
                v-show="showLabelFinal"
                v-if="inputSubType !== 'checkbox'"
        >{{ucFirst(translateString(finalLabel))}}</label>
        <div :class="controlsClass">
            <div class="validation-pending-spinner" v-if="validationPending"  :style="validationPendingStyle">
                <spinner
                        :show="true"
                        :inline="true"
                        :size="0.6"
                        class="validation-pending-spinner"
                ></spinner>
            </div>


            <div :class="inputInnerWrapperClass">

                <span class="uk-form-icon " v-if="icon" :uk-icon="icon"></span>
                <span class="uk-form-icon uk-form-icon-flip" v-if="iconEnd" :uk-icon="iconEnd"></span>
                <span class="uk-form-icon uk-form-icon-flip uk-form-icon-error uk-text-danger"
                    v-if="hasError && errorDisplay == 'slim'"
                    :uk-tooltip="errorMessage"
                    :uk-icon="'warning'"></span>

                <component :is="customInputComponent"
                           :validation="validation"
                           v-if="inputSubType === 'custom'"
                           :id="fieldId"
                           :class="inputClass"
                           :style="inputStyle"
                           :name="name"
                           :type="type"
                           :placeholder="ucFirst(translateString(finalPlaceHolder))"
                           v-model="value"
                           v-bind="inputAttrs"
                           ref="formControl"

                ></component>

                <simple-input
                        v-if="inputSubType === 'input'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :name="name"
                        :type="type"
                        :placeholder="ucFirst(translateString(finalPlaceHolder))"
                        v-model="value"
                        v-bind="inputAttrs"
                        ref="formControl"
                />

                <text-area-input
                        v-if="inputSubType === 'textarea'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :name="name"
                        :placeholder="ucFirst(translateString(finalPlaceHolder))"
                        v-model="value"
                        v-bind="$attrs"
                        ref="formControl"
                >{{value}}</text-area-input>

                <select-input
                        v-if="inputSubType === 'select'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :name="name"
                        :placeholder="finalPlaceHolder"
                        v-model="value"
                        :list="list"
                        v-bind="inputAttrs"
                >
                    <option v-for="option in list" :value="option.value">{{option.label}}</option>
                </select-input>
                <label v-if="inputSubType === 'checkbox'"
                       class="checkbox-label"
                       :class="labelClass"
                       :for="fieldId">
                    <div class="checkbox-wrapper-inner">
                         <simple-input type="checkbox"
                                       :id="fieldId"
                                       :class="inputClass"
                                       :name="name"
                                       v-model="value"
                                       v-bind="$attrs"/>
                        <span style="display: inline-block; margin-inline-start: calc(var(--global-margin) * 0.2);"  v-if=" htmlLabel" v-html="label"></span>
                        <span style="display: inline-block; margin-inline-start: calc(var(--global-margin) * 0.2);" v-if=" ! htmlLabel">{{ucFirst(translateString(finalLabel))}}</span>

                    </div>
               </label>

                <label v-if="inputSubType === 'radio'"
                       :class="labelClass"
                       v-for="(option, index) in list"
                       :for="fieldId + '-' + index">
                    <simple-input type="radio"
                           :id="fieldId + '-' + index"
                           :class="inputClass"
                           :value="option.value"
                           :name="name + '-' + index"
                           v-model="value"
                           v-bind="$attrs"/>
                    {{ucFirst(translateString(option.label))}}
                </label>

            </div>

            <span class="uk-form-error-message uk-text-danger"
                  :class="{'error-absolute-position' : (errorDisplayPosition == 'absolute')}"
                  v-if="hasError && errorDisplay == 'full'">{{errorMessage}}</span>

        </div>
    </div>

    <simple-input
            v-if="inputSubType === 'hidden'"
            :id="fieldId"
            :name="name"
            type="hidden"
            v-model="value"
            v-bind="inputAttrs"
            ref="formControl"
    />
</template>

<script>

    import {getCurrentInstance} from 'vue';
    import FormInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/formInput.js'


    export default {
        mixins: [FormInputMixin],
        props: {

        },
        setup(props) {

        },
        inheritAttrs: false,
        data: function () {
            return {};
        },
        computed : {
            inputStyle () {
                let styles = {};
                let propClass = this.innerClass.split(' ' );

                if (this.inputSize && typeof this.inputSize === 'number') {
                    styles['width'] = this.inputSize+'px';
                }

                // if select and has icon -padding
                if (this.type === 'select' && this.icon) {
                    styles['padding-inline-start'] = 'calc(var(--global-margin)*1.5)'
                }

                return styles;
            },
            inputClass () {
                let inputClass = [];
                let propClass = this.innerClass.split(' ' );

                // error class
                if (this.hasError) {
                    inputClass.push('uk-form-danger');
                }

                // error fixed indicator
                if (this.errorFixed) {
                    inputClass.push('uk-form-success');
                }

                // skip additional styleing if so desired
                if ( ! this.autoInputStyling) {
                    return [...inputClass, ...propClass];
                }

                // apply styiling
                if (this.inputSize && typeof this.inputSize === 'string') {
                    inputClass.push('uk-form-width-' + this.inputSize);
                }

                if (this.type === 'checkbox') {
                    inputClass.push('uk-checkbox');
                    return inputClass
                }

                if (this.type === 'radio') {
                    inputClass.push('uk-radio');
                    return inputClass
                }

                if (this.type === 'textarea') {
                    inputClass.push('uk-textarea');
                    return inputClass
                }

                // select and "input" can have size modifiers
                if (this.type === 'select') {
                    inputClass.push('uk-select');
                } else {
                    inputClass.push('uk-input');
                }

                if (this.size === 'large') {
                    inputClass.push('uk-form-large');
                }

                if (this.size === 'small') {
                    inputClass.push('uk-form-small');
                }

                return [...inputClass, ...propClass];
            },
            labelClass () {
                return ['uk-form-label']
            },
            wrapperClass () {
                let result =  ['input-wrapper'];

                if (this.wrapperMargins) {
                    result.push('uk-margin-bottom');
                }

                if (this.hasError) {
                    result.push('input-wrapper-error');
                }

                result.push(this.class);
                return result;
            },
            controlsClass () {
                let result = ['uk-form-controls'];

                if (this.hasError) {
                    result.push('uk-form-controls-error');
                }


                if (this.type === 'checkbox' || this.type === 'radio') {
                    result.push('uk-form-controls-text');
                }
                if (this.type === 'radio' && this.radioStacked) {
                    result.push('uk-form-controls--stacked');
                }

                return result
            },
            inputInnerWrapperClass () {
                return ['uk-inline', 'input-inner-wrapper']
            },
            validationPendingStyle () {
                if (this.iconEnd && this.icon) {
                    return {'inset-inline-start': '-35px', 'inset-inline-end' : 'auto'}
                }

                if (this.iconEnd) {
                    return {'inset-inline-start': '8px', 'inset-inline-end': 'auto'}
                }

                return {'inset-inline-end': '8px', 'inset-inline-start': 'auto'}
            }
        },
        methods: {

        },

        watch:  {

        },

        created () {

        },


    }

</script>

<style scoped lang="scss">

    .input-wrapper {

    }
    // support the "stacked" radio inside a horizontal form
    .input-wrapper-error {
        margin-bottom: calc(var(--global-margin) * 2);
    }

    .uk-form-controls--stacked label {
        width: auto;
        display: block;
        float: none;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .uk-form-controls {
        position: relative;

        .validation-pending-spinner {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;

            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 5;
        }

    }

    .input-inner-wrapper {
        width: 100%;
        position: relative;


    }

    input {
        // remove firefox autofill yaqiness
        -webkit-appearance: none;
        appearance: none;
        filter: none;
    }

    .uk-form-icon-error {
      pointer-events: auto; // allow tooltip on icon
    }


    .uk-form-error-message.error-absolute-position {
         position: absolute;
         top: 100%;
         left: 0;
         width:100%;
    }

    .checkbox-label {
        cursor: pointer;
        display: inline;
        flex: auto;

        .checkbox-wrapper-inner {
            display: flex;
        }

        .uk-checkbox {
            margin-top: 2px;
            min-width: 16px;
        }
    }
</style>
