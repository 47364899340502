<template>
    <input v-model="value"
           :type="finalType"
           v-bind="$attrs"
           style="padding: 0;margin:0; appearance: none"/>

    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"
          @click="passwordVisible = ! passwordVisible"
          style="pointer-events: auto; cursor: pointer">
    </span>


</template>

<script>
import PasswordInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/input/passwordInput.js'
export default {
    mixins: [PasswordInputMixin],
}
</script>

<style scoped lang="scss">

</style>
