export default {
    install (app) {




        app.mixin({
            updated () {

            },
            created () {

            },
            mounted () {

            }
        })
    },

}
