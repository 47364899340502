export default {
    install (app) {
        app.mixin({
            methods: {
                async performWithUi(cb, optionsOrSuccessText = {}, errorText = true) {
                 
                    let defaultOptions = {
                        spinnerText: false,
                        successText:'core.successGeneric',
                        errorText : 'core.errorOccurredGeneric'
                    };
                    
                    let options = {};
        
                    if (optionsOrSuccessText === false) {
                        optionsOrSuccessText = {successText: false};
                    }
                    
                    // convert string argument
                    if (typeof optionsOrSuccessText === 'string') {
                        optionsOrSuccessText = {successText: optionsOrSuccessText};
                    }
    
                    if (typeof errorText === 'string') {
                        optionsOrSuccessText.errorText = errorText;
                    }
                    // convert any other argument
                    if ( ! optionsOrSuccessText || typeof optionsOrSuccessText !== 'object' ){
                        optionsOrSuccessText = {};
                    }
        
                    options = {...defaultOptions, ...optionsOrSuccessText};
       
                    this.$s.ui.globalSpinner.show();
                    let result;
                    try {
                        result = await cb();
                    } catch (e) {
                        result = false;
                    }
                    this.$s.ui.globalSpinner.hide();
                    
                    // optional success message
                    if (result && options.successText) {
                        this.$s.ui.notification(options.successText, 'success');
                    }
             
                    // optional error message
                    if ( ! result && options.errorText) {
                        this.$s.ui.notification(options.errorText, 'error');
                    }
                    return result;
                },
            }
        })
    },
}
