import { createI18n } from 'vue-i18n/index';
import _ from 'lodash/string';
import _object from 'lodash/object';
import {reactive, computed, ref, watchEffect, nextTick} from 'vue';
import asyncOperationsComposition  from '@/client/extensions/composition/asyncOperations.js';

let appName = process.env.VUE_APP_APPLICATION_NAME;


// todo: language imports language overrides
// todo: language override and imports from apps
let i18n;
let localeObjects;
let i18nOptions = {

};

// methods to populate the i18n
let populateLocaleObjects = () => {
    let objects = {};
    let context;

    // override from app override
    context = require.context('@/', true, /\/overrides\/client\/applications\/[^\/]*\/locale\/.*\/locale.js$/);
    context.keys().forEach(key => {
        // name is the routes file name without extension
        let name = key.replace('/locale.js', '').split('/').pop();
        let noPrefixKey = key.replace ('./overrides/client/applications/', '');

        // check that we only get files from the relevant applet
        if ( ! noPrefixKey.startsWith(appName)) {
            return true;
        }
        if ( ! objects.hasOwnProperty(name)) {
            objects[name] = context(key).default;
        }
    });

    // override from app
    context = require.context('@/', true, /\/client\/applications\/[^\/]*\/locale\/.*\/locale.js$/);
    context.keys().forEach(key => {
        // name is the routes file name without extension
        let name = key.replace('/locale.js', '').split('/').pop();
        let noPrefixKey = key.replace ('./client/applications/', '');

        // check that we only get files from the relevant applet
        if ( ! noPrefixKey.startsWith(appName)) {
            return true;
        }
        if ( ! objects.hasOwnProperty(name)) {
            objects[name] = context(key).default;
        }
    });

    // override from core override
    context = require.context('@/', true, /\/overrides\/client\/locale\/.*\/locale.js$/);
    context.keys().forEach(key => {
        // name is the routes file name without extension
        let name = key.replace('/locale.js', '').split('/').pop();

        if ( ! objects.hasOwnProperty(name)) {
            objects[name] = context(key).default;
        }
    });

    // load from core - last priority
    context = require.context('@/client/locale/', true, /^\.\/.*\/locale.js$/);
    context.keys().forEach(key => {
        // name is the routes file name without extension
        let name = key.replace('/locale.js', '').split('/').pop();
        if ( ! objects.hasOwnProperty(name)) {
            objects[name] = context(key).default;
        }
    });

    localeObjects = objects;
};

let populateI18nOptionsByObjects = (objects) => {
    //
    let options = {
        legacy: true,
        locale: config.locale.defaultLocale,
        messages: {},
        datetimeFormats: {},
        numberFormats: {},
        
    };

    // add all existing language objects WHICH ARE ENABLED IN CONFIG
    for (const [tag, conf] of Object.entries(localeObjects)) {
        // only add enabled locales
        if ( Array.isArray(config.locale.availableLocales)  && ! config.locale.availableLocales.includes(tag)) {
            continue;
        }
        if (conf.datetimeFormats) {
            options.datetimeFormats[tag] = conf.datetimeFormats;
        }

        if (conf.numberFormats) {
            options.numberFormats[tag] = conf.numberFormats;
        }

        if (conf.messages) {
            options.messages[tag] = conf.messages;
        }
    }

    // add automatic dummy to avoid critical errors for missing objects
    for (const tag of config.locale.availableLocales) {
        if (typeof options[tag] !== 'object') {
            options[tag] = {
                tag: tag,
                label: tag,
                labelFull: tag,
                flag: tag,
                useTitleCase: true,
                messages: {}
            };

        }
    }

    i18nOptions = options;
}

let createI18nSingleton = () => {
    i18n = createI18n(i18nOptions);
};

// populate i18n
populateLocaleObjects();
populateI18nOptionsByObjects(localeObjects);
createI18nSingleton();


///////////// i18n "variables"
let messages = {};

let currentLocaleObject = null;


watchEffect( () => {
    currentLocaleObject = localeObjects[i18n.global.locale] || {};
});



//////////// methods to load language, control I18n
/**
 * Method to load language by slug with asyncOps composition
 * @param slug
 * @param app
 * @returns {Promise<*>}
 */
let getLanguageMessagesFromRemote = async (slug, app) => {
    let {asyncOps} = asyncOperationsComposition({}, app ? app.store : undefined);
    let result;
    try {
         result = await asyncOps.asyncCall('language/'+slug+'/messages', {}, {});
    } catch (e) {
        utilities.warn('exception while loading language', 2, e);
        return {};
    }

    return result.data;
};

let getFrontEndMessages = async (slug) => {
    let context;
    let targetModuleName = `./${slug}/messages.js`;
    let messagesModule   = false;

    // load language from front end application override
  //  context = require.context('@/overrides/client/applications/', true, /^\.\/.*\/locale.*\.js$/);
    context = require.context('@/', true, /overrides\/client\/applications\/.*\/locale.*\.js/);

    context.keys().forEach(key => {

        if (messagesModule) {
            return true;
        }

        // check that the applet is correct
        if (! key.startsWith('./overrides/client/applications/'+appName)) {
            return true;
        }

        // check that the slug is correct
        let split = key.split('/');
        let lastSegment = split.pop();
        let moduleSlug = split.pop();

        if (slug !== moduleSlug) {
            return true;
        }

        // check to see that this is the module we want. noticve we keep the '.' at start
        let prefix = `/overrides/client/applications/${appName}/locale`;
        let moduleName = key.replace(prefix, '');

        if (moduleName === targetModuleName) {
            messagesModule = context(key).default || context(key);
            return false;
        }
    });

    // load language from front end application
    context = require.context('@/client/applications/', true, /^\.\/.*\/locale.*\.js$/);
    context.keys().forEach(key => {
        if (messagesModule) {
            return false;
        }

        let prefix = `/${appName}/locale`; // we keep the '.' from the start
        let moduleName = key.replace(prefix, '');

        if (moduleName === targetModuleName) {
            messagesModule = context(key).default || context(key);
        }
    });

    // load language from front end override
   // context = require.context('@/overrides/client/locale', true, /\.js/);
    context = require.context('@/', true, /\/overrides\/client\/locale\/.*\.js/);


    context.keys().forEach(key => {
        if (messagesModule) {
            return false;
        }

        if (key === targetModuleName) {
            messagesModule = context(key).default || context(key);
        }
    });

    // load language from front end core
    context = require.context('@/client/locale', true, /\.js/);
    context.keys().forEach(key => {
        if (messagesModule) {
            return false;
        }

        if (key === targetModuleName) {
            messagesModule = context(key).default || context(key);
        }
    });

    return messagesModule || {};
};

/**
 * Method to load language if required, and getting the messages (either new or that were loaded before)
 * @param slug
 * @param app
 * @returns {Promise<*>}
 */
let lazyLoadLanguage = async (slug, app) => {
    
    // if we have it - return it
    if (typeof messages[slug] === 'object' && messages[slug] !== null) {
        return  messages[slug];
    }
    
    // fetch the language from the front end
    let messagesModule = await getFrontEndMessages(slug); // always returns an object, even if there's no modules to import
    
    // get language from backend, if needed
    let serverMessages = {};
    
    let hasSSR = config.useSSR;
    
    let isHydration = app && app.isSaffronHydrating;
    
    // is ssr hydration, and server provided language
    if (hasSSR && isHydration && window && window.__SAFFRON_LANGUAGE__) {
        messages[slug] = _object.merge(messagesModule, window.__SAFFRON_LANGUAGE__);
        return messages[slug];
    }
    
    // ssr or spa & wait for server language - do async await
    if (config.waitForServerLanguage) {
        serverMessages = await getLanguageMessagesFromRemote(slug, app);
        messages[slug] = _object.merge(messagesModule, serverMessages);
        return messages[slug];
    }
    
    // ssr or spa & NOT wait for server language - do syncronously
    getLanguageMessagesFromRemote(slug, app).then( (result) => {
        messages[slug] = _object.merge(messagesModule, serverMessages);
        return messages[slug];
    });
    
    return  messages[slug];
};

let clearMessages = () => {
    messages = {};
};

let translate =  (key, params = {}, instance, force = false)  => {

    if (typeof key !== 'string' && typeof key !== 'number') {
        return '';
    }

    if (key === '') {
        return key;
    }

    let doTranslate = true;
    
    if (typeof instance.autoTranslate === 'boolean' && ! instance.autoTranslate) {
        doTranslate = false;
    }

    try {
        if (doTranslate || force) {
            return instance.$t(key, params);
        } else {
            return key;
        }
    } catch (e) {
        console.log(e, instance);
        return key;
    }
  
};

let translateNumber = (instance, value, type, localeCode, options) => {
    let defaultTranslateNumber = () => {
        return instance.$n(value, type, localeCode, options);
    }

    if (type === 'currency') {
        value = value / 100;
    }

    if (typeof currentLocaleObject.translateNumber === 'function') {
        return currentLocaleObject.translateNumber(instance, value, type, localeCode, options);
    } else {
        return defaultTranslateNumber();
    }
};

let translateDate = (instance, value, type, localeCode, options) => {
    let defaultTranslateDate = () => {
        return instance.$d(value, type, localeCode, options);
    }
    
   
    if (typeof currentLocaleObject.translateDate === 'function') {
        return currentLocaleObject.translateDate(instance, value, type, localeCode, options);
    } else {
        return defaultTranslateDate();
    }
};

let resetI18n = async (app) => {
    let targetLocale = i18n.global.locale;

    // clear messages, and reload them
    clearMessages();
    let newMessages = await lazyLoadLanguage(targetLocale, app);

    // once done, assign to i18n. then quickly switch and switch back locals, to trigger a re-render
    i18n.global.setLocaleMessage(targetLocale, reactive(newMessages));
    i18n.global.locale = null;
    i18n.global.locale = targetLocale;
};

let assignLanguageFromWindow = () => {
    messages[config.locale.defaultLocale] = Object.assign({}, window.__SAFFRON_LANGUAGE__);
}

let loadInitialLanguage = async (app) => {
    if ( typeof window === 'undefined' ) {
        await lazyLoadLanguage(config.locale.defaultLocale, app);
        return true;
    }
  
    let hasWindowObject = typeof window !== 'undefined' && window.__SAFFRON_LANGUAGE__ && typeof window.__SAFFRON_LANGUAGE__ === 'object';
    let isRenderingSsrInWindow = config.useSSR && ! utilities.isSSR() && window;
  
    if ( ! isRenderingSsrInWindow) {
        await lazyLoadLanguage(config.locale.defaultLocale, app);
        return true;
    }
    
    if ( ! hasWindowObject) {
        await lazyLoadLanguage(config.locale.defaultLocale, app);
        return true;
    }
   
    return new Promise((fulfil) => {
        nextTick (async () => {// nextTick is an attempt to prevent unloaded language that randomly happens
            await utilities.wait(50); // attempt to solve the issue where page shows with no language strings initially with service worker
            messages[config.locale.defaultLocale] = Object.assign({}, window.__SAFFRON_LANGUAGE__);
            fulfil()
        })
    });

    
}

export default new Promise(async (resolve) => {
    let topApp = null;
    if (config.waitForServerLanguage) {
        await loadInitialLanguage(topApp); // TODO: get preferred locale from state somehow, if available
    } else {
        loadInitialLanguage(topApp).then(() => {});
    }


    let vuePlugin = {
        install (app, test) {
            let store    = app.config.globalProperties.$store;
            
            // populate the app
            let topApp = app;
            
            // observe store language change
            watchEffect( async () => {
                let slug = store.getters['locale/slug'];
                let messages = await lazyLoadLanguage(slug, topApp);
                i18n.global.setLocaleMessage(slug, reactive(messages));
                i18n.global.locale = slug;
                resetI18n(topApp);
            });

           app.use(i18n);

           app.globalTranslate = i18n.global.t;

           app.mixin({
                props : {
                    autoTranslate: {
                        type: Boolean,
                        default: true
                    },
                },
                beforeUnmount() {
                    // sometimes for some reason some components lose $i18n before unmount and then they blow up, this is to patch this
                    if (typeof this.$i18n === 'undefined') {
                        this.$i18n = {};
                    }
                },
                methods: {
                    i18nReset() {
                        resetI18n(topApp);
                    },
                    switchLocale(slug) {
                        store.commit('locale/slug', slug);
                    },
                    getLocaleObjects() {
                        return localeObjects;
                    },
                    getLocaleObject() {
                        return currentLocaleObject;
                    },
                    setLocale(slug) {
                        store.commit('locale/slug', slug);
                    },
                    safeTranslate (key, params = {}, force = false) {
                        return translate(key, params, this, force);
                    },
                    forceTranslate (key, params = {}) {
                        return translate(key, params = {}, this, true);
                    },
                    translateSafe (key, params = {}, force = false) {
                        return translate(key, params, this, force);
                    },
                    translate (key, params = {}, force = false) {
                        return translate(key, params, this, force);
                    },
                    translateTitleCase(key, params = {}, force = false) {
                        let translation  = translate(key, params, this, force);

                        if(currentLocaleObject && currentLocaleObject.useTitleCase === false) {
                            return utilities.ucFirst(_.toLower(translation));
                        } else {
                            return  utilities.titleCase(_.toLower(translation));
                        }
                    },
                    translateUcFirst(key, params = {}, force = false) {
                        let translation  = translate(key, params, this, force);
                        return utilities.ucFirst(_.toLower(translation));
                    },
                    translateNumber (value, type, localeCode, options) {
                        return translateNumber(this, value, type, localeCode, options);
                    },
                    translateDate (value, type, localeCode, options) {
                        return translateDate(this, value, type, localeCode, options);
                    },
                    translateDateNoTz (value, type, localeCode, options) {
                        if (! options || typeof options !== 'object') {
                            options = {};
                        }
                        options.ignoreTimezone = true;
                        return translateDate(this, value, type, localeCode, options);
                    },
                    isLanguageRtl() {
                        return i18n.global.locale === 'he-IL' || i18n.global.locale.startsWith('ar-') || i18n.global.locale.startsWith('AR-') ;
                    },
                    getLocale() {
                        return i18n.global.locale;
                    },
                    getLocaleShort() {
                        return i18n.global.locale.substring(0,2);
                    }
                }

            });
        },
    };

    resolve(vuePlugin);
});


