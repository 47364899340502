export default {
    install (app) {




        app.mixin({
            updated () {

            },
            created () {

            },
            mounted () {

            },
            methods : {
                ucFirst (val) {
                    return utilities.ucFirst(val);
                }
            }
        })
    },

}
