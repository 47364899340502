<template>

    <block class="uk-flex uk-flex-center" >
        <ul class="uk-pagination uk-flex uk-flex-center"
           :class="{rtl : isLanguageRtl()}"
            v-show="forceShow || totalPages > 1" >

            <li class="pagination-icon">
                <a href="#" @click.prevent="goToFirst()">
                    <span uk-icon="chevron-double-left"></span>
                </a>
            </li>
            <li class="pagination-icon">
                <a href="#" @click.prevent="goToPrev()">
                    <span uk-icon="chevron-left"></span>
                </a>
            </li>



            <li v-for="link of prevPageLinks"
                :key="link"
                :class="{'uk-disabled':link.disabled}">
                <a href="#" @click.prevent="goToPage(link.page)">{{link.label}}</a>
            </li>


            <li class="uk-active"><span>{{currentPage + 1}}</span></li>

            <li v-for="link of nextPageLinks"
                :key="link"
                :class="{'uk-disabled':link.disabled}">
                <a href="#" @click.prevent="goToPage(link.page)">{{link.label}}</a>
            </li>


            <li class="pagination-icon">
                <a href="#" @click.prevent="goToNext()">
                    <span uk-icon="chevron-right"></span>
                </a>
            </li>

            <li class="pagination-icon">
                <a href="#" @click.prevent="goToLast()">
                    <span uk-icon="chevron-double-right"></span>
                </a>
            </li>
        </ul>
    </block>

</template>

<script>

    import PaginationMixin from '@/client/extensions/mixins/baseComponents/pagination.js'


    export default {
        mixins: [PaginationMixin],
        props: {

        },
        data: function () {
            return {

            };
        },
        computed : {

        },
        methods: {
        },
        watch : {

        }


    }
</script>

<style scoped lang="scss">
    // align uk icon to look visually correct in pagination
    .uk-pagination > *.pagination-icon > * {
        padding: 4px 0 5px;
    }


    .rtl .uk-icon {
        transform:rotate(180deg);
    }

</style>
