<template>

    <block class="flex-center flex-middle flex-column width-expand" >
        <ul class="pagination flex-center"
           :class="{rtl : isLanguageRtl()}"
            v-show="forceShow || totalPages > 1" >

            <li class="pagination-icon">
                <a href="#" @click.prevent="goToFirst()">
                    <icon icon="chevrons-inline-start"></icon>
                </a>
            </li>
            <li class="pagination-icon">
                <a href="#" @click.prevent="goToPrev()">
                    <icon icon="chevron-inline-start"></icon>
                </a>
            </li>

            <li v-for="link of prevPageLinks"
                :key="link"
                class="m:visible"
                :class="{'disabled':link.disabled}">
                <a href="#" @click.prevent="goToPage(link.page)">{{link.label}}</a>
            </li>


            <li class="active"><span>{{currentPage + 1}}</span></li>

            <li v-for="link of nextPageLinks"
                :key="link"
                class="m:visible"
                :class="{'disabled':link.disabled}">
                <a href="#" @click.prevent="goToPage(link.page)">{{link.label}}</a>
            </li>


            <li class="pagination-icon">
                <a href="#" @click.prevent="goToNext()">
                    <icon icon="chevron-inline-end"></icon>
                </a>
            </li>

            <li class="pagination-icon">
                <a href="#" @click.prevent="goToLast()">
                    <icon icon="chevrons-inline-end"></icon>
                </a>
            </li>
        </ul>
        <span class="pagination-totals m:hidden">
            {{translateUcFirst('core.pagination.pageSummary', {currentPage: currentPage+1, totalPages: totalPages})}}
        </span>
        <block v-if="handlesPageSize" class="margin-m-top " style="max-width: 150px" >

            <form-input type="select"
                        :nullOption="{value: 0, selectable: false, label : translate('core.pagination.selectSizePlaceholder')}"
                        :autoTranslate="false"
                        v-model="computedPageSize"
                        :list="computedPaginationSizeSelectList">

            </form-input>
        </block>
    </block>

</template>

<script>

    import PaginationMixin from '@/client/extensions/mixins/baseComponents/pagination.js'


    export default {
        mixins: [PaginationMixin],
        props: {
            handlesPageSize : {
                type: Boolean,
                default: false,
            }
        },
        data: function () {
            return {

            };
        },
        computed : {

        },
        methods: {
        },
        watch : {

        }


    }
</script>

<style scoped lang="scss">
    // align uk icon to look visually correct in pagination
    .uk-pagination > *.pagination-icon > * {
        padding: 4px 0 5px;
    }


    .rtl .uk-icon {
        transform:rotate(180deg);
    }

</style>
