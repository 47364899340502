export default {
   
    props: {
        fitHeight: {
            type: Boolean,
            default: true
        },
        defaultHeight: {
            type: Number,
            default: 200
        },
        template: {
            type: String,
            values: ['story','line','circle','square', 'form', 'thinLines'],
            default: 'story'
        },
        count: {
            type: Number,
            default: 1
        },
        shapeDimension: {
            type: Number,
            default: 70
        },
        contentReady : {
            type: Boolean,
            default: false,
        },
        inverse: {
            type: Boolean,
            default: false
        },
        class : {
            type : String,
            default: '',
        },
        style: {
            type: [String, Object],
            default: '',
        }
    },
    data: function () {
        return {
            mounted: false,
        };
    },
    computed: {
        height () {
            if ( ! this.mounted) {
                return this.defaultHeight
            }
            
            try {
                return this.$refs.outerContainer.$el.parentElement.offsetHeight;
            } catch (e) {
                return this.defaultHeight
            }
        },
        containerCss () {
            let styleProp = this.style ?? '';
            if (typeof styleProp === 'string') {
                let heightProp = `height: ${this.height} + 'px'`;
                return heightProp + ' ' + styleProp;
            }
    
            if (styleProp && typeof styleProp === 'object') {
                styleProp.height = this.height + 'px';
                return styleProp;
            }
          
      
        },
        circleCss () {
            return {
                height: this.shapeDimension + 'px',
                flex: this.shapeDimension + 'px',
                width: this.shapeDimension + 'px',
            }
        },
        squareCss () {
            return {
                height: this.shapeDimension + 'px',
                flex: this.shapeDimension + 'px',
                width: this.shapeDimension + 'px',
            }
        },
        hasContent () {
            return typeof this.$slots.default === 'function';
        },
        containerClass () {
            let containerClass = this.class.split();
            
            containerClass.push('skeleton-wrapper')
            
            if (this.inverse) {
                containerClass.push('skeleton-wrapper--inverse');
            }
            
            return containerClass;
        }
        
    },
    mounted () {
        this.mounted = true;
    }
    
}
