<template>
    <label :for="labelFor">
        <slot v-if="showSlot"></slot>
        <span v-if=" ! showSlot">{{translate(label)}}</span>
    </label>
</template>

<script>
import FreeLabelMixin from '@/client/extensions/mixins/baseComponents/form/controls/freeLabel.js'

    export default {
        mixins: [FreeLabelMixin],
        props: {


        },
        data: function () {
            return {};
        },
        computed: {

        }
    }
</script>

<style scoped lang="scss">

</style>
