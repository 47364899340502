<template >
    <p>
        <slot></slot>
    </p>
</template>

<script>
export default {};
</script>

