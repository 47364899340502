<template>
    <div class="repeatable-wrapper panel bg-gray-1 padding-l"
         v-sortable="{onUpdate:onReorder, handle: '[data-role=handle]'}">
        <div class=" panel  padding-l margin-l-bottom  animate--fast"
             :class="{'animate__fadeIn' : this.addingNewItems && index == (value.length - 1)}"
                 v-for="(item, index) of value" :key="uniqueId+'-row-'+index" :cleanItem="getItemClean(item)">

                <div class="item " >
                    <div class="fields-grid margin-inline-end width-expand">
                        <div class="item-fields-wrapper  flex flex-wrap gap-m width-expand">
                            <div class="field-immediate-wrapper  animate--fast"
                                 :class="{'animate__flipInX' : this.addingNewItems && index == (value.length - 1)}"
                                 v-for="(subItem, subIndex) of getItemClean(item)" :key="uniqueId+'-cell-'+index" :subItem="subItem">

                                    <form-input
                                            v-bind="fields[subIndex]"
                                            :name="name + '-' + index + '-' + subIndex"
                                            :validation="fields[subIndex].validation"
                                            :doValidations="true"
                                            :type="fields[subIndex].type"
                                            :id="name+index+subIndex"
                                            v-model="value[index][subIndex]"
                                    />

                            </div>
                        </div>

                    </div>

                    <div class="flex gap-xs row-buttons-container">
                        <form-button
                                    icon="move"
                                    theme="lead"
                                    :slim="true"
                                    data-role="handle"
                                    class="margin-s-inline-end"
                                    :showSlot="false"
                                    style="cursor: move"
                                    @click.stop.prevent="removeRow(index)"
                                ></form-button>

                                <form-button
                                        icon="x"
                                        theme="danger"
                                        :slim="true"
                                        :showSlot="false"
                                        @click.stop.prevent="removeRow(index)"
                                ></form-button>

                            <!-- TODO: bug with form-button, where due to prop binding - @ will bind twice -->
                        </div>
                </div>
            </div>

        <div class="repeatable-field-footer">
            <div class="flex flex-end ">
                <form-button
                        icon="plus"
                        type="primary"
                        @click.prevent="addRow"
                >
                    <span v-if="value && value.length > 0">{{translate('core.form.repeatable.addAnother')}}</span>
                    <span v-else>{{translate('core.form.repeatable.addFirst')}}</span>
                </form-button>
            </div>
        </div>
    </div>

</template>

<script>
    import RepeatableInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/input/repeatableInput.js'

    export default {
        mixins: [RepeatableInputMixin],
        setup (props) {

        },
        data() {
            return {}
        },
        computed: {

        },
        methods: {
            // custom input class implementation
            getInputClass (type) {
                let inputClass = [];

                  if (this.inputSize && typeof this.inputSize === 'string') {
                      inputClass.push('uk-form-width-' + this.inputSize);
                  }
                  // error class

                  if (this.hasError || false) {
                      inputClass.push('uk-form-danger');
                  }

                  // error fixed indicator
                  if (this.errorFixed || false) {
                      inputClass.push('uk-form-success');
                  }

                if (type === 'checkbox') {
                    inputClass.push('uk-checkbox');
                    return inputClass
                }

                if (type === 'radio') {
                    inputClass.push('uk-radio');
                    return inputClass
                }

                if (type === 'textarea') {
                    inputClass.push('uk-textarea');
                    return inputClass
                }

                // select and "input" can have size modifiers
                if (type === 'select') {
                    inputClass.push('uk-select');
                } else {
                    inputClass.push('uk-input');
                }

                if (type === 'large') {
                    inputClass.push('uk-form-large');
                }

                if (type === 'small') {
                    inputClass.push('uk-form-small');
                }

                return inputClass
            },
        },
        watch : {

        },
    }
</script>

<style scoped lang="scss">

.item-fields-wrapper  {
    display: flex;
    flex-wrap: wrap;
    padding-inline-end: var(--margin-l);

    @media (max-width: 1024px) {
        padding-inline-end: var(--margin-m);
    }
}

.field-immediate-wrapper {
    flex: 0 0 calc(50% - (var(--margin-s)));
    min-width: 200px;

    @media (max-width: 1024px) {
        flex-grow: 500;
        flex: 150px;
        min-width: 0;

    }

}

.item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    @media (max-width: 767px) {
        flex-direction: column-reverse; // buttons on top, instead of on the side
        gap: var(--margin-m);

        .row-buttons-container {
            width: 100%;
           *:first-child {
               margin-inline-start: auto;
           }
        }
    }
}

.row-buttons-container {
    margin-top: 27px;
    @media (max-width: 767px) {
        margin-top: 0;
        width: 100%;

        *:first-child {
            margin-inline-start: auto;
        }
    }
}
</style>
