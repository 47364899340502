<template>
    <input v-model="value"
           :class="inputClass"
           :type="'text'"
           v-bind="$attrs"
           :value="mainInputDisplayValue"
           ref="baseInput"

           @click.stop="openDropdown()"
           style="appearance: none; cursor: pointer"/>


    <div class="select2-dropdown  relative"
             v-dropdown="{opened: isDropDownOpened, applyAnimations: true}"
             @dropdown:closed="isDropDownOpened = false"
             @dropdown:opened="focusDropdown"
             @click.stop=""

             @keyDown="listKeyboardNavigation"
             ref="listDropDown">

            <div class="filter-wrapper flex-between gap-m margin-m-bottom"   v-if="showFilterField">
                <form-input type="text"
                            class="select2-filter-input width-expand"
                            ref="searchInput"
                            autocomplete="false"
                            :wrapperMargins="false"
                            placeholder="core.select2.filterPlaceholder"
                            icon="magnify"

                            @keydown="listKeyboardNavigation"
                            @focus="currentHighlight = null"
                            @click="currentHighlight = null"
                            v-model="textFilter"></form-input>

                <icon class="select2-dropdown-close"
                      v-show="showCloseIcon"
                      @click="closeDropdownImmediate"
                      :size="1.5"
                      theme="gray"
                      icon="x"></icon>
            </div>

            <icon class="select2-dropdown-close select2-dropdown-close--absolute"
                  v-if=" ! showFilterField"
                  @click="closeDropdownImmediate"
                  :size="1.5"
                  theme="gray"
                  icon="x"></icon>

            <div class="options-wrapper">
                <span v-if="listFetchError">{{translate('core.select2.fetchError')}}</span>

                <spinner text="core.loading" v-if="listLoading"/>

                <clean-wrapper v-if="displayList.length > 0 && ! listLoading && ! listFetchError">


                    <block v-for="(item, index) of displayList"
                           :item="item"
                           :index="index"
                           :key="item.value"
                           :autoTranslate="translateList"
                           @click="toggleItemSelection(item), clearHighlight()"
                           :ref="'option-'+index"
                           :tabindex="index+1"
                           class="dropdown-option-wrapper animate__fadeIn animate--fast">


                        <component
                            :is="itemComponent"
                            :item="item"
                            :selected="isSelected(item)"
                            :autoTranslate="translateList"
                            :class="{'highlight' : index == currentHighlight}"
                            v-temp-class-on="'click,indicating-selection,850'">

                        </component>

                    </block>
                </clean-wrapper>

                <clean-wrapper v-if="displayList.length < 1 && ! listLoading && ! listFetchError">
                    <span class="animate__fadeIn animate--fast">{{ translate('core.select2.noResults') }}</span>
                </clean-wrapper>
            </div>


        </div>


    <icon class="we-icon icon field__icon field__icon--end"
          v-if="showChevron"
          theme="gray"
          icon="chevron-down"
          @click.stop="openDropdown()"
          style="pointer-events: auto; cursor: pointer; transition: transform 175ms ease-in;"
          :style="dropdownChevronStyle"
    ></icon>
</template>

<script>
import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
import select2Mixin from '@/client/extensions/mixins/baseComponents/form/controls/input/select2Input.js'
export default {
    props: {

    },
    mixins: [select2Mixin],
    setup: function (props) {
        let {asyncOps, asyncOpsReady} = asyncOperations(props);

        return {asyncOps, asyncOpsReady}
    },
    data: function () {
        return {
           // isDropDownOpened : true,
        };
    },
    emits: [ ],
    computed: {

    },
    methods: {

    },
    watch: {

    },
}
</script>

<style scoped lang="scss">
// scrollbar styles for drop down

.select2-dropdown {
    width: 100%;
    background: var(--c-bg);
    border: 2px solid var(--c-gray);
    $scrollbar-bg: var(--c-gray-2);
    $scrollbar-color:  var(--c-gray-5);


    max-height: 40vh;
    padding: 0;

    scrollbar-color: $scrollbar-color $scrollbar-bg;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-bg;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-color;
        outline: none;
    }

    .filter-wrapper{
        padding: var(--margin-m) var(--margin-m) 0 var(--margin-m);
    }


    .options-wrapper {
        overflow: auto;
        max-height: calc(40vh - 70px);
        margin-top: var(--margin-m);
        padding: 0 var(--margin-m);
        scrollbar-color: $scrollbar-color $scrollbar-bg;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: $scrollbar-bg;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $scrollbar-color;
            outline: none;
        }
    }

    &.open {
        border: 2px solid var(--c-lead);
    }
}

.dropdown-option-wrapper {
    position: relative;
    background: var(--c-gray);


}

.select2-dropdown > * {
    cursor: pointer;
}

.filter-wrapper{
    align-items: center;
}

.select2-filter-input {

}

.select2-dropdown-close {
    transform: none;
    transition: transform 200ms ease-in-out;

    &--absolute {
        position: absolute;
        top: var(--margin-s);
        inset-inline-end : var(--margin-s);
        z-index: 5;
        padding: var(--margin-s);
        border: 1px solid red;
    }

    &:hover {
        transform: rotate(180deg);
    }
}



</style>
