<template>
    <div class="card width-expand" :class="cardClass">
        <div class="card__header" v-if="hasAnyHeaderContent || $slots['header']">
            <slot name="header" :card="this">
                   <span class="card__title">
                    <slot name="title" :card="this">
                        <icon :icon="icon" v-if="icon"></icon>
                        <slot name="title-text" :card="this" v-if="title || $slots['title']">
                            <span>
                                {{ translateSafe(title)}}
                            </span>
                        </slot>
                         <badge ref="badge"
                                :class="{'force-flex flex-middle' : badgeText && badgeIcon}"

                                @click="doBadgeAction()"
                                :style="{cursor: badgeAction ? 'pointer' : 'default'}"
                                v-if="badgeText || badgeIcon || $slots['badge']"
                                :theme="finalBadgeTheme"
                                :size="finalBadgeSize" >
                              <slot name="badge" :card="this">
                                   <icon
                                       :class="{'margin-s-inline-end' : badgeText && badgeIcon}"
                                       :icon="badgeIcon"
                                         v-if="badgeIcon" size="1.4"></icon>
                                  <span v-if="badgeText">{{ translateSafe(badgeText) }}</span>
                              </slot>
                         </badge>
                    </slot>

                </span>
                <span class="card__sub-title" v-if="subTitle || $slots['sub-title']">
                    <slot name="sub-title" :card="this">
                          <span>{{ translateSafe(subTitle)}}</span>
                    </slot>
                </span>
            </slot>
        </div>
        <div class="card__body">
            <slot name="default" :card="this"></slot>
         </div>

        <div class="card__footer" v-if="$slots['footer']">
            <slot name="footer" :card="this"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        theme: {
            type: [String],
            default: 'default'
        },
        // standard/slim
        layout: {
            type: [String],
            default: 'standard'
        },
        title: {
            type: [String, Boolean],
            default: false
        },
        icon : {
            type: [String, Boolean],
            default: false,
        },
        subTitle: {
            type: [String, Boolean],
            default: false
        },
        badgeText: {
            type: [String, Boolean],
            default: false
        },
        badgeTheme: {
            type: [String],
            default: 'default'
        },
        badgeSize: {
            type: [String],
            default: 'l'
        },
        badgeIcon : {
            type: [String, Boolean],
            default: false,
        },
        badgeAction : {
            type: [Function, Boolean],
            default: false,
        }
    },
    data: function () {
        return {};
    },
    computed: {
        hasAnyHeaderContent() {
          return this.title ||  this.subTitle || this.badgeText ||  this.icon;
        },
        finalTheme () {
            return this.theme || 'default';
        },
        finalBadgeTheme () {
            return this.badgeTheme || 'default';
        },
        finalBadgeSize () {
            return this.badgeSize || 'default';
        },
        cardClass () {
            let result = ['card'];
            result.push('card--' + this.finalTheme);
            return result;
        },
    },
    methods : {
        doBadgeAction () {
            if (typeof this.badgeAction === 'function') {
                this.badgeAction({card: this, badge: this.$refs.badge});
            }
        }
    }

}
</script>

<style scoped lang="scss">

</style>
