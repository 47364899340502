<template>
    <div class="admin-layout-wrapper">

        <nav class=" admin-navbar container uk-navbar-container flex flex-center bg-text z-effect-5 flex z-3  padding-m-vertical padding-max-horizontal "  >
            <div class="flex flex-start padding-s gap-m">
<!--
                <a href="#"
                   @click.prevent=""
                   class="uk-navbar-item force-show menu-toggle"
                   hidden>
                    <inline uk-icon="menu"></inline>
                </a>

                <a href="#"
                   @click.prevent=""
                   class="uk-navbar-item force-show menu-toggle"
                   uk-toggle="target: .uk-navbar-item.menu-toggle, .side-menu-container; animation: uk-animation-fade">
                    <inline uk-icon="close"></inline>
                </a>
-->
                <router-link :to="{path: '/'}" class="uk-navbar-item">
                    <div class="icon-wrap bg-gray-1 padding-xs radius-sharp">
                        <icon
                            size="2"
                            icon="cube"
                        ></icon>
                    </div>

                </router-link>

                <div class="uk-navbar-item">
                    <div class="icon-wrap bg-gray-1 padding-xs radius-sharp">
                         <icon class=""
                               size="2"
                               icon="magnify"
                         ></icon>
                    </div>

                </div>

            </div>
            <div class="navbar-right">
                <!--
                <div class="navbar-item">
                    <div class="admin-notification-widget">
                        <icon class="notification-icon" icon="bell">
                            <badge size="s" class="badge uk-badge">2</badge>
                        </icon>
                    </div>
                </div>-->
                <div class="navbar-item flex  flex-start padding-s gap-m">
                    <div class="user-widget-loading" v-if="! isloggedStatusKnown">
                        <spinner :inline="true" :text="safeTranslate('user.stateLoading')"></spinner>
                    </div>
                    <div class="user-widget flex" v-if="isloggedStatusKnown">
                        <router-link tag="a" v-if=" ! isLoggedIn" :to="{name: 'user-login'}">Login</router-link>

                        <a v-if="isLoggedIn" @click="logout" class="icon-wrap bg-gray-1 padding-xs radius-sharp block">
                            <icon class=""
                                  size="2"
                                  icon="arrow-logout-inline-end"
                            ></icon>

                        </a>
                    </div>
                </div>
            </div>

            <div class="uk-navbar-item">
                    <icon class="icon"
                          icon="x"
                          uk-toggle="target: .uk-navbar-item:not(.force-show); animation: uk-animation-fade"></icon>
            </div>
            <!--
            <inline class="uk-navbar-item width-expand">

                <form-input
                    style="display: none;"
                    name="search"
                    label=""
                    :showLabel="false"
                    placeholder="core.searchPlaceholder"
                    type="text"
                    icon="search"
                    size="small"
                    class="uk-width-expand"
                    :wrapperMargins="false"
                ></form-input>
            </inline>
-->

        </nav>

        <div class=" background-bg-4 admin-outer-grid flex gap-m " >
            <div class="container side-menu-container container-s background-text c-bg-4 box-shadow-5">
                <div class="side-menu">
                    <ul class="side-nav">

                        <a class="uk-navbar-item side-menu-logo" href="#" :style="logoStyle"></a>

                        <li v-for="link of links"
                            :key="link"
                            :link="link">
                            <router-link
                                :to="link.to">
                                <inline class=" margin-small-left margin-small-inline-end" :uk-icon="link.icon"></inline>
                                <span>{{safeTranslate(link.shortLabel)}}</span>
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="main-content-wrapper width-expand margin-l-bottom" >
                <slot name="default"></slot>
            </div>
        </div>



    </div>
</template>

<script>
    import {computed, watchEffect} from 'vue'
    export default {
        props: {
            'defaultComponent' : String,
            logo: {
                type: String,
                default: 'logos/main.png'
            }
        },
        data: function () {
            return {
                links: [
                    {
                        to: {name:'admin-dashboard'},
                        icon: 'settings',
                        shortLabel: 'admin.dashboard.titleShort',
                        label: 'admin.dashboard.title',
                        description: 'admin.dashboard.description',
                    },
                    {
                        to: {name:'entity-dashboard'},
                        icon: 'copy',
                        shortLabel: 'admin.entity.titleShort',
                        label: 'admin.entity.title',
                        description: 'admin.entity.description',
                    },
                    {
                        to: {name: 'admin-user'},
                        icon: 'user',
                        shortLabel: 'admin.user.titleShort',
                        label: 'admin.user.title',
                        description: 'admin.user.description',
                    },
                    {
                        to: {name:'admin-language'},
                        icon: 'world',
                        shortLabel: 'admin.language.titleShort',
                        label: 'admin.language.title',
                        description: 'admin.language.description',
                    },
                    {
                        to: {name:'admin-config'},
                        icon: 'cog',
                        shortLabel: 'admin.config.titleShort',
                        label: 'admin.config.titleShort',
                        description: 'admin.config.description',
                    }
                ]
            };
        },
        computed: {
            isloggedStatusKnown () {
                return computed(() =>this.$store.getters['user/isTokenValid'] !== null);
            },
            isLoggedIn () {
                return computed(() => this.$store.getters['user/isTokenValid']);
            },
            logoStyle () {
                let url = utilities.requireAsset('images/' + this.logo);
                return {
                    backgroundImage : "url('"+url+"')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center 5px',
                    height:         '78px',
                    minHeight:      '0',
                    backgroundSize: '60px auto',
                };
            }

        },
        methods: {
            logout() {
                this.$store.commit('user/invalidateAuth');
                this.$store.commit('user/clearProfile');
                this.$router.push('/');
            }
        },
        mounted () { }

    }
</script>

<style scoped lang="scss">


    .admin-layout-wrapper{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .admin-outer-grid {
        flex-direction: row;
        flex: 100%;
    }

    .side-menu-container, .container.side-menu-container {
        width: 180px;
        flex:0 0 180px;
        padding-inline-end: 0;
    }

    .side-menu {
        width: 100%;
        flex: 100%;

        .side-menu-logo {
            display: block;
            height: 100px;
            width: 100px;
            margin: var(--margin-xl) auto var(--margin-l) auto;
        }

        .side-nav {
            list-style: none;
            padding-inline-start: 20px;

            li {
                margin-bottom: var(--margin-l);
            }


            a:not(.logo) {
                display: block;
                width: 100%;
                list-style: none;
                color: var(--c-bg);
                font-size: var(--font-l);
                padding: var(--margin-s);
                position: relative;
                padding-inline-start: var(--margin-s);
                padding-inline-end: var(--margin-s);
                transform: none;
                transition: padding 100ms ease-in;

                &.uk-active, &:hover {
                    text-decoration: none;
                    padding-inline-start: var(--margin-m);
                    &:before {
                        position: absolute;
                        content: ' ';
                        width: 3px;
                        height: 100%;
                        inset-inline-start: 0;
                        top: 0;
                        background: var(--c-alt);
                    }
                }
            }
        }


    }

    .admin-notification-widget {
        .notification-icon {
            position: relative;
            cursor: pointer;

            .notification-badge {
                position: absolute;
                top: -70%;
                right: -50%;
                z-index: 2;
            }
        }
    }
</style>
