<template>
    <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
        <slot />
    </a>
    <router-link v-bind="$attrs" :to="to" :class="extraClass" v-else >
        <slot></slot>
    </router-link>
</template>

<script>
import { RouterLink, useLink } from 'vue-router'
export default {
    name: 'SaffronLink',
    inheritAttrs: false,
    setup(props) {
        return useLink(props);
    },
    props: {
        ...RouterLink.props,
        inactiveClass: String,
        exactActiveOnly: true,
    },
    data: function () {
        return {

        };
    },
    computed: {
        isExternalLink() {
            return typeof this.to === 'string' && this.to.startsWith('http')
        },
        extraClass() {

            let routeValue = this.$router.currentRoute.value;

            if ( ! this.route.name) {
                return '';
            }

            if ( ! routeValue || ! routeValue.meta || ! routeValue.meta.saffronPageParents ) {
                return '';
            }

            let parentNames = routeValue.meta.saffronPageParents;


            if (parentNames.includes(this.route.name)) {
                return config.router.linkActiveClass;
            } else {
                return '';
            }


        }
    },
    mounted () {

    }


}
</script>

<style scoped lang="scss">

</style>
