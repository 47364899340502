export default {
    props: {
        /**
         * Is the modal showing - uses watch internally to implement
         **/
        show : {
            type: Boolean,
            default: false,
        },
        /**
         * Title for the modal.
         * False - no modal header
         * True - show default :title attribute text
         * True + content to #title slot - show custom slot html
         */
        title: {
            type: [Boolean, String],
            default: false,
        },
        centerTitle: {
            type: Boolean,
            default: false,
        },
        /**
         * Show Little X close button on top?
         */
        closeButton: {
            type: Boolean,
            default: true
        },
        /**
         * easyClose
         * Should we allow closing with "esc" press or tap/click on background
         */
        easyClose: {
            type: Boolean,
            default: true
        },
        /**
         * Show footer? default is true. There is also a slot for the footer content
         */
        showFooter: {
            type: Boolean,
            default: true,
        },
        verticalCenter: {
            type: Boolean,
            default: false
        },
        keyboardClose: {
            type: Boolean,
            default: true,
        }
    },
    data: function () {
        return {
            localShow : this.show,
        };
    },
    computed : {
        titleText() {
            if (typeof this.title !== 'string') {
                return this.translate('core.ui.modal.promptTitle');
              
            }
    
            return this.translateTitleCase(this.title);
        }
    },
    emits: [
        'modal:confirmed', 'modal:closed',
        'modal:beforeClose', 'modal:beforeHide',
        'modal:afterClose', 'modal:afterClosed', 'modal:afterHide',
        'modal:beforeShow', 'modal:beforeOpen',
        'modal:afterShown', 'modal:afterOpened',
    ],
    inject: ['windowEvents'],
    methods: {
        emitClosed() {
            this.$emit('modal:closed');
        },
        closeModal (context) {
            this.$emit('modal:beforeClose', {context: context});
            this.$emit('modal:beforeHide', {context: context});
            utilities.waitUntil(()=> {return typeof this.$s == 'object'}, 10).then( (result) => {
                this.$s.ui.modal(this.$refs.modal).hide();
                this.$emit('modal:closed', {context: context});
                this.$emit('modal:afterHide', {context: context});
                this.$emit('modal:afterClose', {context: context});
                this.$emit('modal:afterClosed', {context: context});
            });
        },
        openModal () {
            this.$emit('modal:beforeShow');
            this.$emit('modal:beforeOpen');
            utilities.waitUntil(()=> {return typeof this.$s == 'object'}, 10).then( (result) => {
                this.$s.ui.modal(this.$refs.modal).show();
                this.$emit('modal:afterShown');
                this.$emit('modal:afterShow');
                this.$emit('modal:afterOpened');
            });
         
        },
        showModal () {
          
            return this.openModal();
           
        },
        hideModal (context) {
            return this.closeModal(context);
        },
        open () {
            return this.openModal();
        },
     
        close(context) {
            return this.closeModal(context);
        },
        hide () {
            return this.closeModal();
        },
        okClickHandler(context) {
            this.localShow = false;
            this.hideModal(context);
            this.$emit('modal:closed'); // this allows parent to fallow state, and update the show prop if they want
            this.$emit('modal:confirmed');
        },
        handleKeyup(e) {
            if (e.keyCode === 27 && this.keyboardClose && this.localShow) {
                this.hideModal();
            }
        }
       
    
    },
    created () {
    
    },
    mounted () {
        this.windowEvents.on('keyup', this.handleKeyup);
    },
    beforeUnmount () {
        this.windowEvents.off('keyup', this.handleKeyup);
    },
    watch: {
        show: {
            immediate: true,
            handler (newVal) {
                if (utilities.isSSR()) {
                    return;
                }
                
                if (newVal) {
                    this.localShow = newVal;
                    this.showModal();
                } else {
                    this.localShow = newVal;
                    this.hideModal();
                }
                
            }
        },
      
        localShow : {
            immediate: true,
            handler(newVal) {
            
            }
        }
       
    }
}
