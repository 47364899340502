<template >
    <span>
        <slot></slot>
    </span>
</template>

<script>
export default {};
</script>

