<template>
    <teleport to="#modals">
        <div class="modal animate__fadeIn animate--fast"
             :class="modalClass"
             v-bind="$attrs"
             ref="modal"
             v-if="localShow"
             @keydown="handleKeyboardClose"
             @click.self="easyClose ? closeModal('reject') : null">
            <div class="modal__inner animate__fadeInUpSmall animate--faster"
                :class="{'modal__inner--full' : this.isFull}">
                <icon class="modal__icon-close"
                      v-if="closeButton" :size="1.8"
                      color="gray" @click="closeModal('reject')" icon="x" />

                <div class="modal__header" v-if="title || $slots['title']">
                    <slot name="title" :modal="this">
                        <h3 class="modal__title" :class="{'modal__title--center' : centerTitle}" >{{titleText}}</h3>
                    </slot>

                </div>

                <div class="modal__body">
                    <slot default :modal="this"></slot>
                 </div>

                <div class="modal__footer"
                     v-if="showFooter  || $slots['footer']">
                    <slot name="footer" :modal="this">
                        <form-button @click="okClickHandler('reject')">{{translate('core.ui.modal.okButtonText')}}</form-button>
                    </slot>
                 </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import ModalMixin from '@/client/extensions/mixins/baseComponents/modal.js'
    export default {
        mixins: [ModalMixin],
        props: {
            full: {
                type: [Boolean, String],
                default: false,
            },
            class: {
                type: [String],
                default : '',
            },
            selfClose: {// todo: implement?
                type: [Boolean],
                default: true,
            },
            showHeader: { // todo: implement?
                type: [Boolean],
                default: true,
            }
        },
        data: function () {
            return {

            };
        },
        computed : {
            isFull () {
                return this.full === 'true' || this.full === true;
            },
            modalClass() {
                let result = ['z-level-top-2'];

                if (this.localShow) {
                    result.push('active');
                }

                if (this.title || this.$slots['title']) {
                    result.push('modal--has-header');
                }

                if (this.showFooter || this.$slots['footer']) {
                    result.push('modal--has-footer');
                }

                result.push(this.class);
                return result;
            }
        },
        methods: {
            closeModal (context) {
                this.$emit('modal:beforeClose', {context: context});
                this.$emit('modal:beforeHide', {context: context});
                this.localShow = false;
                this.$emit('modal:closed', {context: context});
                this.$emit('modal:afterHide', {context: context});
                this.$emit('modal:afterClose', {context: context});
            },
            openModal () {
                this.$emit('modal:beforeShow');
                this.$emit('modal:beforeOpen');
                this.localShow = true;

                this.$emit('modal:afterShown');
                this.$emit('modal:afterOpened');
            },

        },
        created () {

        },
        watch: {
            show(newVal) {
                console.log("show is updated");
            }
        }
    }
</script>

<style scoped lang="scss">
    .uk-modal-footer {
        text-align: end;
    }
</style>
