<template>

    <div :class="overlayClass"
         v-show="show"
         v-if="showOverlay"
         v-bind="$attrs"
         :style="overlaySpinnerStyle"
         >
        <div class="spinner-wrapper">
            <div :uk-spinner="spinnerAttributes" v-if="showSpinner"></div>
            <div class="spinner-text" v-if="hasText">{{finalSpinnerText}}</div>
            <slot></slot>

            <progress class="uk-progress"
                      :value="progress"
                      max="100"
                      v-if="hasProgress"></progress>
        </div>
    </div>

    <div :style="noOverlaySpinnerStyle"
         :class="nonOverlayWrapperClass"
         v-if=" ! showOverlay"
         v-show="show"
         v-bind="$attrs">
        <div class="inner">
            <div :uk-spinner="spinnerAttributes" v-if="showSpinner"></div>
            <div class="spinner-text" v-if="hasText" v-show="show">{{translate(text)}}</div>
            <slot></slot>
            <progress class="uk-progress" :value="progress" max="100" v-if="hasProgress" v-show="show"></progress>
        </div>
    </div>
</template>

<script>
    /**
     * Spinner
     * @displayName spinner (frameworks/uikit - loaders)
     */
    import SpinnerMixin from '@/client/extensions/mixins/baseComponents/loaders/spinner.js'
    export default {
        mixins: [SpinnerMixin],
        props: {

        },
        data: function () {
            return {};
        },
        computed : {
            spinnerAttributes : function () {
                let ratio = this.size;

                if (ratio === true && this.overlay === 'absolute') {
                    ratio = 2;
                    return "ratio: " + 2;
                }

                if (ratio === true && this.overlay === 'fixed') {
                    return "ratio: " + 3;
                }

                if (ratio === true) {
                    return "ratio: " + 1;
                }

                return "ratio: " + ratio;
            },
            showOverlay : function () {
                return this.overlay === 'absolute' || this.overlay === 'fixed' || this.overlay;
            },
            overlayClass : function () {
                let val = this.overlay;
                if (this.overlay === true) {
                    val = 'default';
                }

                return ['spinner-overlay-wrapper', 'spinner-overlay-wrapper--'+val, this.class];
            },
            nonOverlayWrapperClass : function () {
                let classes = ['spinner-wrapper', 'spinner-wrapper--no-overlay' , this.class];
                if (this.inline) {
                    classes.push('spinner-wrapper--inline');
                }
                return classes;
            },
            overlayBackground: function () {
                let val = "rgba(255,255,255,0.8)";

                if (typeof this.overlayOpacity === 'string') {
                    switch(this.overlayOpacity) {
                        case 'none':
                        case 'transparent':
                            return "rgba(255,255,255,0.00)";
                            break;
                        case 'light':
                            return "rgba(255,255,255,0.20)";
                            break;
                        case 'dark':
                            return "rgba(255,255,255,0.85)";
                            break;

                    }
                }

                if (typeof this.overlayOpacity === 'number') {
                    return "rgba(255,255,255," + this.overlayOpacity + ")";
                }

                // use default
                return val;
            },
            overlaySpinnerStyle () {
                return {'backgroundColor': this.overlayBackground, cursor : this.cursor}
            },
            noOverlaySpinnerStyle () {
                return {cursor : this.cursor};
            },
        }

    }
</script>

<style scoped lang="scss">
    .spinner-overlay-wrapper {
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 50;
            background: rgba(255,255,255, 0.45);
            display: flex;
            justify-content: center;
            text-align: center;

            &.spinner-overlay-wrapper--absolute {
                position: absolute;
            }

            &.spinner-overlay-wrapper--fixed {
                position: fixed;
            }

            .spinner-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                > *  {
                   margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .uk-spinner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

    .spinner-wrapper--inline, .spinner-wrapper--no-overlay.spinner-wrapper--inline {
        display: inline-block;
        vertical-align: middle;
    }
    .spinner-wrapper--no-overlay {
        display: flex;

       .inner {
           display: flex;

           > * {
               -webkit-margin-inline-end: 8px;
               margin-inline-end: 8px;

               &:last-child {
                   -webkit-margin-inline-end: 0;
                   margin-inline-end: 0;
               }
           }
       }

    }
</style>
