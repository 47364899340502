import {ref, reactive, computed} from 'vue';

let globalRefreshInterval;
let globalRefreshDelay = 3 * 1000;
let finalNetworkQuality = computed(() => {
    if (hasNetworkQuality.value) {
        return currentNetworkQuality;
    }
    return false;
});
let isIntervalSet = false;

let currentNetworkQuality = reactive({
                                         speed: 0,
                                         grade: 0,
                                         label: ''
                                     })
let hasNetworkQuality = ref(false);
let isUpdating = ref(false);
export default (options = {}) => {
    let immediate = false;
    
    if (utilities.isSSR()) {
        return {
            networkQuality : false,
            isUpdating: false,
            networkQualityChecked: false,
            updateConnectionQuality : () => {return false},
        };
    }
    
    let defaultThresholds = [
        {
            speed: 1,
            grade: 0,
            label: 'core.networkQuality.veryPoor',
        },
        {
            speed: 3,
            grade: 1,
            label: 'core.networkQuality.poor',
        },
        {
            speed: 5,
            grade: 2,
            label: 'core.networkQuality.good',
        },
        {
            speed: 15,
            grade: 3,
            label: 'core.networkQuality.veryGood',
        },
        {
            speed: 25,
            grade: 4,
            label: 'core.networkQuality.excellent',
        }
    ];
    
    let thresholds;
    
    let implementOptions = () => {
        // normalize
        if ( ! options || typeof options !== 'object') {
            options = {};
        }
        
        // thresholds
        thresholds = options.thresholds ? options.thresholds : defaultThresholds;
        
        if (options.checkInterval) {
            globalRefreshDelay = options.checkInterval;
        }
        
        if (options.immediate || options.checkImmediatly) {
            immediate = true;
        }
    };
    
    let getConnectionSpeed  = async () => {
        if (utilities.isSSR()) {
            return false;
        }
        
        let imageAddr    = "https://via.placeholder.com/3000C/O";
        let downloadSize = 1355900 * 8;// 13055440;
        let isDone = false;
        let testTimeout;
        let getConnectionSpeedPromise = new Promise(async (fulfil, reject) => {
            let download    = new Image();
            let startTime   = (new Date()).getTime();
            download.src    = imageAddr + "?nnn=" + startTime;
            download.onload = () => {
                clearTimeout(testTimeout);
                if (isDone) {
                    fulfil(0.01);
                }
                let duration  = ((new Date()).getTime() - startTime) / 1000;
                let speedMbps = (downloadSize / duration / (1024 * 1024)).toFixed(2);
                fulfil(speedMbps);
            }
            
            download.onerror = function (err, msg) {
                fulfil(0.01);
            }
    
            // this stuff only runs in window, is ssr sage
            testTimeout = setTimeout(() => {
                fulfil(0.01);
            }, 5000);
        });
        
        return await getConnectionSpeedPromise;
    };
    
    let setNetworkQualityBySpeed = (speed) => {
        let level = thresholds[0];
     
        for (const threshold of thresholds) {
    
            if ( speed > threshold.speed) {
                level = threshold;
                continue;
            } else {
                break;
            }
          
           
        }
        thresholds.forEach( (threshold) => {
        
        });
        
        currentNetworkQuality.speed = speed;
        currentNetworkQuality.grade = level.grade;
        currentNetworkQuality.label = level.label;
        
    };
    
    let updateConnectionQuality = async () => {
        isUpdating.value = true;
        let speed  = await getConnectionSpeed();
        isUpdating.value = false;
       
        currentNetworkQuality.speed = speed;
        
        if (speed) {
            setNetworkQualityBySpeed(speed);
            hasNetworkQuality.value = true;
            return true;
        }
        
        hasNetworkQuality.value = false;
        return false;
    }
    
    
    implementOptions();
    
    if (immediate) {
        updateConnectionQuality();
    }
    clearInterval(globalRefreshInterval);
    
    // this is SSR safe, because a dummy was returned on SSR and this never runs on ssr
    if (utilities.isSSR()) {
        throw new Error('this must not run on the server');
    }
    globalRefreshInterval = setInterval(() => {
        updateConnectionQuality();
    }, globalRefreshDelay);
    
    
  
    return {
        networkQuality : currentNetworkQuality,
        isUpdating: isUpdating,
        networkQualityChecked: hasNetworkQuality,
        updateConnectionQuality : updateConnectionQuality,
    };
};
