<template>

    <div id="" class="app-inner-wrapper">
        <app-header class="z-top-1"></app-header>
        <div class="app-wrapper container margin-auto">
            <slot></slot>
        </div>
    </div>

</template>
<script setup>

</script>
<script>
import AppHeader from "@/client/applications/hear/components/AppHeader.vue";
    export default {
        props: {

        },
        components: {
            AppHeader: AppHeader,
        },
        data () {
            return {
                prevHeight: 0,
            }
        },
        computed: {


        },
        methods: {

        },
        mounted () {

        }
    }
</script>

<style lang="scss" scoped >
    .app-wrapper {
        min-height: calc(100vh - 2 * var(--margin-max));
        padding: var(--margin-max) 0;
    }

</style>
