<template >
    <span class="icon-text flex flex-middle gap-s">
        <icon :icon="icon" class="icon-text__icon"></icon>
        <span class="icon-text__text" :class="textClass">
             <slot>{{translate(text)}}</slot>
        </span>

    </span>
</template>
<script>
import IconTextMixin from '@/client/extensions/mixins/baseComponents/html/iconText.js'

    export default {
        mixins: [IconTextMixin],

        props: {

        }
    }
</script>
<style scoped lang="scss">


</style>
