<template>
    <div :class="wrapperClass"
         :style="wrapperStyle"
         v-responsiveClasses=""

         :id="id"

         v-if="inputSubType != 'hidden'">

        <label  :for="fieldId"
                :class="labelClass"
                v-show="showLabelFinal"

                v-if="inputSubType !== 'checkbox'"
        >
            <span>{{ucFirst(translateString(finalLabel))}}</span>
            <icon style="cursor: help;"
                  icon="question"
                  size="1.3"
                  theme="gray-5"
                  v-if="tooltip"
                  v-tooltip="computedTooltip"></icon>
        </label>

        <div :class="innerWrapperClass">
            <div class="field__controls-spinner" v-if="validationPending"  :style="validationPendingStyle">
                <spinner
                        :show="true"
                        :inline="true"
                        :size="0.6"
                        class="validation-pending-spinner"
                ></spinner>
            </div>

            <div :class="inputInnerWrapperClass">

                <icon class="field__icon" v-if="icon" :icon="icon" :size="iconSize" :theme="iconTheme" />
                <icon class="field__icon field__icon--end" v-if="iconEnd" :size="iconSize" :theme="iconTheme" :icon="iconEnd" />
                <icon class="field__icon field__icon--end"
                      v-if="hasError && errorDisplay == 'slim'"
                      :icon="'warning-triangle'" :theme="'danger'" />


                <component :is="customInputComponent"
                           :validation="validation"
                           v-if="inputSubType === 'custom'"
                           :id="fieldId"
                           :class="inputClass"
                           :style="inputStyle"
                           :name="name"
                           :type="type"
                           :placeholder="ucFirst(translateString(finalPlaceHolder))"
                           v-model="value"
                           v-bind="inputAttrs"
                           ref="formControl"

                ></component>

                <simple-input
                        v-if="inputSubType === 'input'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :name="name"
                        :type="type"
                        :placeholder="ucFirst(translateString(finalPlaceHolder))"
                        v-model="value"
                        v-bind="inputAttrs"
                        ref="formControl"
                />

                <text-area-input
                        v-if="inputSubType === 'textarea'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :name="name"
                        :placeholder="ucFirst(translateString(finalPlaceHolder))"
                        v-model="value"
                        v-bind="$attrs"
                        ref="formControl"
                >{{value}}</text-area-input>

                <select-input
                        v-if="inputSubType === 'select'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :name="name"
                        :placeholder="finalPlaceHolder"
                        v-model="value"
                        :list="list"
                        v-bind="inputAttrs"
                >
                    <option v-for="option in list" :value="option.value">{{option.label}}</option>
                </select-input>

                <label v-if="inputSubType === 'checkbox'"
                       :class="labelClass"
                       :for="fieldId">
                    <div class="field__controls-wrapper field__controls-wrapper--checkbox">
                         <simple-input type="checkbox"
                                       :id="fieldId"
                                       :class="inputClass"
                                       :name="name"
                                       v-model="value"
                                       v-bind="$attrs"/>
                        <span class="field__label-content" v-if=" htmlLabel" v-html="label"></span>
                        <span class="field__label-content" v-if=" ! htmlLabel">{{ucFirst(translateString(finalLabel))}}</span>
                    </div>
               </label>

                <label v-if="inputSubType === 'radio'"
                       :class="labelClass"
                       v-for="(option, index) in list"
                       :for="fieldId + '-' + index">
                    <simple-input type="radio"
                           :id="fieldId + '-' + index"
                           :class="inputClass"
                           :value="option.value"
                           :name="name + '-' + index"
                           v-model="value"
                           v-bind="$attrs"/>
                    <span class="field__label-content" v-if=" htmlLabel" v-html="label"></span>
                    <span class="field__label-content" v-if=" ! htmlLabel">{{ucFirst(translateString(option.label))}}</span>
                </label>

            </div>
            <span v-show="legend" class="field__legend">
                {{translate(legend)}}
            </span>

            <span class="field__error c-danger animate--faster animate__fadeIn"
                  :class="{'error-absolute-position' : (errorDisplayPosition == 'absolute')}"
                  v-show="hasError && errorDisplay == 'full'">{{errorMessage}}</span>

        </div>
    </div>

    <simple-input
            v-if="inputSubType === 'hidden'"
            :id="fieldId"
            :name="name"
            type="hidden"
            v-model="value"
            v-bind="inputAttrs"
            ref="formControl"
    />
</template>

<script>

    import {getCurrentInstance} from 'vue';
    import FormInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/formInput.js'


    export default {
        mixins: [FormInputMixin],
        props: {
            iconTheme: {
                type: [String],
                default: 'gray-4'
            },
            iconSize: {
                type: [Number],
                default: 1.2
            }
        },
        setup(props) {

        },
        inheritAttrs: false,
        data: function () {
            return {};
        },
        computed : {
            inputStyle () {
                let styles = {};
                let propClass = this.innerClass.split(' ' );

                if (this.inputSize && typeof this.inputSize === 'number') {
                    styles['width'] = this.inputSize+'px';
                }

                // if select and has icon -padding //TODO: probably dont need this now
                if (this.type === 'select' && this.icon) {
                  //  styles['padding-inline-start'] = 'calc(var(--global-margin)*1.5)'
                }

                return styles;
            },
            inputClass () {
                let inputClass = [];
                let propClass = this.innerClass.split(' ' );

                inputClass.push('field__element--style-'+this.themeStyle);

                // error class
                if (this.hasError) {
                    inputClass.push('field__element--error');
                }

                // error fixed indicator
                if (this.errorFixed) {
                    inputClass.push('field__element--success');
                }



                // skip additional styleing if so desired
                if ( ! this.autoInputStyling) {
                    return [...inputClass, ...propClass];
                }

                // apply styiling // TODO: support this in weUI?
                if (this.inputSize && typeof this.inputSize === 'string') {
                    inputClass.push('field__element--width-' + this.inputSize);
                }

                if (this.type === 'checkbox') {
                    inputClass.unshift('field__element');
                    inputClass.push('field__element--checkbox');
                    return inputClass
                }

                if (this.type === 'radio') {
                    inputClass.unshift('field__element');
                    inputClass.push('field__element--radio');
                    return inputClass
                }

                if (this.type === 'textarea') {
                    inputClass.unshift('field__element');
                    inputClass.push('field__element--textarea');
                    return inputClass
                }

                // select and "input" can have size modifiers
                if (this.type === 'select') {
                    inputClass.unshift('field__element--select');
                } else {
                    inputClass.unshift('field__element');
                }

                if (this.size === 'large' || this.size === 'l') {
                    inputClass.push('field__element--l');
                }

                if (this.size === 'small' || this.size === 's') {
                    inputClass.push('field__element--s');
                }

                return [...inputClass, ...propClass];
            },
            inputInnerWrapperClass () {
                let result =  ['field__controls', 'field__controls--inline', 'field__controls--' + this.inputSubType];

                result.push('field__controls--style-'+this.themeStyle);

                if (this.inputSubType === 'radio' && this.radioStacked) {
                    result.push('field__controls--stacked');
                }
                if (this.hasError) {
                    result.push('field__controls--error');
                }

                if (this.errorFixed) {
                    result.push('field__controls--success');
                }

                return result;
            },
            labelClass () {
                return [
                    'field__label',
                    'field__label--' + this.inputSubType,
                    'field__label--style-'+this.themeStyle
                ]
            },
            wrapperClass () {
                let result =  [
                    'field',
                    'field--style-'+this.themeStyle
                ];

                if (this.labelInline === true || this.labelInline === 'true') {
                    result.push('field--label-inline')
                }

                if (this.forceLabelInline === true || this.forceLabelInline === 'true') {
                    result.push('field--force-label-inline')
                }
                if ( ! this.wrapperMargins) {
                    result.push('margin-remove');
                }

                if (this.hasError) {
                    result.push('field--error');
                }

                result.push(this.class);
                return result;
            },
            innerWrapperClass () {
                let result = [
                    'field__controls-wrapper',
                    'field__controls-wrapper--'+ this.inputSubType,
                    'field__controls-wrapper--style-'+this.themeStyle
                ];

                if (this.hasError) {
                    result.push('field__controls-wrapper--error');
                }

                if (this.type === 'radio' && this.radioStacked) {
                    result.push('field__controls-wrapper--stacked');
                }

                if (this.errorFixed) {
                    result.push('field__controls-wrapper--success');
                }

                return result
            },

            validationPendingStyle () {

                //TODO
                if (this.iconEnd && this.icon) {
                    return {'inset-inline-start': '-35px', 'inset-inline-end' : 'auto'}
                }

                if (this.iconEnd) {
                    return {'inset-inline-start': '8px', 'inset-inline-end': 'auto'}
                }

                return {'inset-inline-end': '8px', 'inset-inline-start': 'auto'}
            }
        },
        methods: {

        },

        watch:  {

        },

        created () {

        },


    }

</script>

<style scoped lang="scss">

.field__controls {
    position: relative;


}

.field__controls-spinner {
    position: absolute;
    top: 0;
    inset-inline-end: -2.1em;
}



</style>
