export default {

    props: {
        modelValue    : {},
        list: {}, // prevent inheritence of list="" on element
        characterCounter: {
            type: [Object, Boolean],
            default: false,
        },
        
    },
    data: function () {
        return {};
    },
    emits: ['update:modelValue'],
    computed: {
        
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        inputAttrs() {
            let attrs = {...this.$attrs};
            delete attrs.list;
            return attrs;
        },
        hasCharacterCounter() {
            return typeof this.characterCounter === 'object' && this.characterCounter !== null;
        },
        maxCharacters () {
            if ( ! this.characterCounter) {
                return 0;
            }
            
            return this.characterCounter.max || this.translate('core.unlimited');
        },
        currentCharacters () {
            return this.value ? this.value.length : 0;
        }
    },
}
