<template>

    <Skeleton
        :class="skeletonClass"
              template="form"
              :fitHeight="false"
              :defaultHeight="skeletonHeight"
              :count="expectedFields"
              :contentReady="asyncStatus.asyncDataReady">
        <form class="uk-form-stacked uk-margin"
              :class="formClass"
              v-if="asyncStatus.asyncDataReady"
              :method="formMethod"
              :disabled="formDisabled"
              :action="action"
              @submit.prevent="handleSubmit()"
              @reset.prevent="clear(), untouchAll()">

            <div class="fields"  :class="finalFieldWrapperClass">

                <alert
                        class="form-alert"
                        v-if="showSubmitError"
                        type="danger"
                        :animation="true"
                        close-type="event"
                        @alert:closing="hideSubmitError">
                    {{finalGeneralErrorMessage}}
                </alert>
                <component
                        v-for="(group, groupKey) in getGroupedFields()"
                        key="groupKey"
                        :is="group.component"
                        v-bind="group.props"
                >
                    <component
                            v-for="(field, fieldIndex) in getGroupFieldsForRendering(group)"
                            :is="field.component"
                            v-bind="field"
                            :error-display-position="errorDisplayPosition"
                            :key="groupKey+'-field-'+fieldIndex"
                            v-model="formData[field.name]"
                            @focusout="allowFieldErrorDisplay(fieldIndex)"
                            @input="touchInput(fieldIndex)"
                            :validation-pending="isFieldValidationPending(fieldIndex)"
                            :error-message="getFieldValidationErrorMessage(fieldIndex)"
                    ></component>


                </component>


                <slot name="afterFields"></slot>
                <component
                        v-for="(group, groupKey) in getGroupedButtons()"
                        key="groupKey"
                        :is="group.component"
                        v-bind="group.props">

                    <component
                            v-for="(button, buttonIndex) in group.buttons"
                            :is="button.component"
                            :disabled="buttonsDisabled"
                            :loading="buttonsLoadingStatus[groupKey][buttonIndex]"
                            :key="groupKey+'-button-'+buttonIndex"
                            v-bind="getButtonConfig(buttonIndex)">
                        {{safeTranslate(button.text)}}
                    </component>
                </component>
                <slot name="afterButtons"></slot>
                <div class="recaptcha-information">
                    <div v-show="preflightTypes.includes('recaptcha')"
                        class="recaptcha-text"
                         style="font-size: 8px; margin-top: 3px;">
                        <span>* Protected by Google reCAPTCHA</span>, <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">TOS</a> apply.
                    </div>
                </div>
            </div>



            <spinner v-if="showLoadingOverlay"
                     :text="overlaySpinnerText"
                     overlay="absolute"
                     :show="true"></spinner>
        </form>

    </Skeleton>

</template>

<script>
    const _ = require('lodash/object');

    import useValidation from "@/client/extensions/composition/useValidation";
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import useRecaptcha  from '@/client/extensions/composition/useRecaptcha.js';
    import useVuelidate from '@vuelidate/core'

    import { reactive} from 'vue';

    import BaseFormMixin from '@/client/extensions/mixins/baseComponents/form/baseForm.js'

    export default {
        mixins: [BaseFormMixin],
        setup (props, context) {
            let {asyncOps, asyncOpsReady, asyncStatus,} = asyncOperations(props);
            let {isCaptchaReady, executeCaptcha} = useRecaptcha();
            return {
                v$ : useVuelidate(),
                asyncOps, asyncOpsReady, asyncStatus,
                ...useValidation(props),
                isCaptchaReady,
                executeCaptcha
            }
        },
        props: {

        },
        data: function () {
            return {
                primaryButtonDefaultClass: 'uk-button-primary',
            }
        },
        computed: {
            finalFieldWrapperClass () {
                let result = this.fieldWrapperClass;
                if ( this.fieldWrapperMargin) {
                    result = result + ' ' + 'uk-margin-medium-bottom'
                }

                return result;
            },

            skeletonHeight () {
                // 85 px for each form field, + another such unit for the button
                return (this.expectedFields +1) * this.controlGroupHeight;
            },

            skeletonClass () {
                if (this.class) {
                    return 'form-skeleton form-skeleton--'+this.class;
                }

                return 'form-skeleton';
            },

            formClass () {
                if (this.class) {
                    return 'form form--'+this.class;
                }

                return 'form';
            }
        },
        watch: {

        },
        validations () {
            return {
                formData : reactive(this.getValidationRules() || {})
            }
        },
        serverPrefetch () {
            return this.getSSRPrefetchPromise();
        },
    }
</script>

<style scoped lang="scss">
// so alert plays nice with flex layouts
 .form-alert {
     flex: 100%;
 }

</style>


<style lang="scss">
// NON SCOPED STYLE - we can hide recaptcha badge because we handle the legal stuff ourselves
.grecaptcha-badge { visibility: hidden; }
</style>

