<template>

    <div class="wrapper uk-flex uk-flex-gap" :class="class">

        <input v-model="value"
               style="width: 20%; cursor: pointer; height: 100%; appearance: none; background: transparent; border: none; -webkit-appearance: none"
               v-bind="inputAttrs"
               :type="'color'"
               ref="inputElement"
               @focus="isFocused = true"
               @blur="isFocused = false"/>
        <div class="preview" @click.prevent="$refs.inputElement.click()" style="cursor: pointer;">{{ value }}</div>
    </div>





</template>

<script>
import ColorInputMixin from '@/client/extensions/mixins/baseComponents/form/controls/input/colorInput.js'

export default {
    mixins: [ColorInputMixin]
}
</script>

<style scoped lang="scss">

</style>
