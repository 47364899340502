import {nextTick} from 'vue';

export default {
    props: {
        modelValue    : {},
        fields : {
            type: Object,
            default: {}
        },
        inputSize: {
            type: [Boolean, String, Number],
            default: false,
        },
        /**
         * Prevent class attr from being automatically set. This causes visual issue when used within FormInput
         */
        class: {
            type: String,
            default : ''
        },
        name          : {
            type: [String, Boolean],
            default: false,
        },
        errorMessage : {
            type: [String, Array],
            default: ''
        },
    },
    data: function () {
        return {
            //  validationModel: {},
            errorFixed : false,
            addingNewItems: false,
            addingItemsTimeout: null,
            addingItemsTimeoutLength: 600
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        hasError () {
            return this.errorMessage !== '';
        },
        uniqueId () {
            return utilities.getUniqueNumber();
        }
        
    },
    created () {
    
    },
    methods: {
        enforceValueIntegrity () {
            if ( ! Array.isArray(this.value)) {
                this.value = [];
            }
        },
        addRow () {
            clearTimeout(this.addingItemsTimeout)
            this.addingNewItems = true;
            let row = {};
            let value = this.value;
            
            for (const [key, field] of Object.entries(this.fields)) {
                row[key] = field.defaultValue || null;
            }
            
            this.value = [...value, row];
            utilities.setClientTimeout(() => {
                try {
                    this.addingNewItems = false;
                } catch (e) {
                    //
                }
            }, this.addingItemsTimeoutLength)
          
        },
        onReorder (e) {
            let list = [...this.value];
            
            function moveElement(array,initialIndex,finalIndex) {
                array.splice(finalIndex,0,array.splice(initialIndex,1)[0])
                return array;
            }
            
            let result =  moveElement ( list, e.oldIndex, e.newIndex);
            // we can not just set this. vue will display wrong html relative to it's own state.
            this.$emit('update:modelValue', []);
            nextTick(() => {
                this.$emit('update:modelValue', result);
            });
            
        },
        removeRow (index) {
            this.value.splice(index, 1);
        },
        updateSubValue (itemIndex, itemKey, val) {
        
        },
        getInputClass (type) {
            let inputClass = [];
            
            if (this.inputSize && typeof this.inputSize === 'string') {
                inputClass.push('uk-form-width-' + this.inputSize);
            }
            // error class
            
            if (this.hasError || false) {
                inputClass.push('uk-form-danger');
            }
            
            // error fixed indicator
            if (this.errorFixed || false) {
                inputClass.push('uk-form-success');
            }
            
            if (type === 'checkbox') {
                inputClass.push('uk-checkbox');
                return inputClass
            }
            
            if (type === 'radio') {
                inputClass.push('uk-radio');
                return inputClass
            }
            
            if (type === 'textarea') {
                inputClass.push('uk-textarea');
                return inputClass
            }
            
            // select and "input" can have size modifiers
            if (type === 'select') {
                inputClass.push('uk-select');
            } else {
                inputClass.push('uk-input');
            }
            
            if (type === 'large') {
                inputClass.push('uk-form-large');
            }
            
            if (type === 'small') {
                inputClass.push('uk-form-small');
            }
            
            return inputClass
        },
        getItemClean (input) {
            let item = Object.assign({}, input);
            
            if (typeof item._id !== 'undefined') {
                delete item._id;
            }
            
            return item;
        },
    },
    emits: ['update:modelValue'],
    watch : {
        value: {
            handler: 'enforceValueIntegrity',
            deep: true,
            immediate: true
        },
        /*
         modelValue: {
         handler:   'enforceValueIntegrity',
         deep: true,
         immediate: true
         },*/
    },
}
