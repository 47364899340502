<template>
    <div class="main-nav">
        <ul class="nav nav-default">

            <li class="nav-header">{{ translate('hear.menu.user') }}</li>
            <li>
                <router-link :to="{name: 'subject-details'}" @click="$emit('menu:navigated')" >
                    <icon-text  icon="user" text="">{{ translate('hear.menu.subjectDetails') }}</icon-text>
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'admin-login'}" @click="$emit('menu:navigated')">
                    <icon-text icon="user" text="">{{ translate('hear.menu.admin') }}</icon-text>
                </router-link>
            </li>
            <li class="nav-divider"></li>
            <li class="nav-header">{{ translate('hear.menu.tests') }}</li>
            <li>
                <router-link :to="{name: 'tests-testa'}" @click="$emit('menu:navigated')">
                    <icon-text icon="clipboard-check" text="">{{ translate('hear.menu.DINTest') }}</icon-text>
                </router-link>
            </li>
            <li class="nav-divider" v-if="isAdmin"></li>
            <li class="nav-header" v-if="isAdmin">{{ translate('hear.menu.options') }}</li>
            <li v-if="isAdmin">
                <router-link :to="{name: 'settings-testa'}" @click="$emit('menu:navigated')">
                    <icon-text icon="sliders-horizontal" text="">{{ translate('hear.menu.DINTestOptions') }}</icon-text>
                </router-link>
            </li>
        </ul>

        <form-button
            v-if="isAdmin"
            class="margin-large-top"
            @click="logout()"
            text="hear.menu.adminDisconnect"></form-button>
    </div>

</template>

<script>

    import WeHorizontalRuler from "@/client/applications/hear/components/base/frameworks/we/html/WeHorizontalRuler";
    export default {
        name: "MainMenu",
        components: {WeHorizontalRuler},
        emits: [
            'menu:navigated'
        ],
        data: function () {
            return {}
        },
        computed : {
            isAdmin () {
                return this.$store.getters['user/isAdmin'];
            }
        },
        methods : {
            logout : function () {
                this.$store.commit('user/logout');
                this.$s.ui.notification('hear.admin.successfulLogout');
                this.$router.push({name: 'index'});
            }
        }
    };

</script>

<style lang="scss" scoped>

ul.nav {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: var(--margin-s);
        font-size: var(--font-l);

        .icon-text {
            color: var(--c-gray-2);
            transition: color 200ms ease;
        }

        a {
            display: block;
            height: auto;
            width: 100%;

            &.active {
               font-weight: bold;

                .icon-text {
                    color: var(--c-gray-1);
                }


            }
        }

        a:hover {
            text-decoration: none;

            .icon-text {
                color: var(--c-gray-1);

            }
        }
    }

    li.nav-header {
        font-size: var(--font-xl);
      //  margin-top: var(--margin-xl);
        color: var(--c-lead-2);


        &:first-of-type {
            margin-top: 0;
        }
    }

    li.nav-divider {
        height: 0;
        margin: var(--margin-xl) 0;
        width: 100%;
        flex: 100%;
        border-bottom: 1px solid var(--c-gray-4);
    }
}
</style>
