export default {
    props: {
        active: {
            type: Boolean,
            default: true,
        },
        fixed: {
            type: Boolean,
            default: true
        }
    }
};
