<template>

    <block class="admin-dashboard-view" :class="{'rtl' :isLanguageRtl()}">

        <div class="container  container--expand flex flex-between padding-l padding-xl-vertical  flex-wrap">
            <div class="title-wrapper margin-m-inline-end relative">
                <h3 class="margin-remove flex flex-middle">
                    <div class=" title-back-icon-wrap margin-s-inline-end font-inverse background-lead "
                         :uk-tooltip="translate('core.goBack')"

                         v-if="showTitleBackLink"
                         style="border-radius: 100%;padding: 2px; cursor: pointer; "

                         @click="goBack()">

                        <icon class="title-back-icon"
                              theme="bg"
                              icon="chevron-inline-start" ></icon>
                    </div>
                    <div class="title-wrapper">
                        <slot name="title">{{translateTitleCase('admin.dashboard.defaultTitle')}}</slot>
                    </div>
                </h3>
            </div>

            <div class="breadcrumbs-wrapper flex flex-middle">
                <slot name="breadcrumbs">
                    <breadcrumbs></breadcrumbs>
                </slot>
            </div>


        </div>

        <div class="container container--expand">
            <slot name="mainContent">
                <card class="">

                    <div v-if="showToolbar" class="admin-view-toolbar ">
                        <!-- using slot toolbar overwrites the whole thing. consumer can also just use toolbarStart /end to use our premade layout-->
                        <slot name="toolbar">
                            <block class="actions uk-flex uk-flex-between">
                                <block class="actions__start uk-flex">
                                    <slot name="toolbarStart"></slot>
                                </block>
                                <block class="actions__end uk-flex">
                                    <slot name="toolbarEnd"></slot>
                                </block>
                            </block>

                        </slot>
                    </div>

                    <div class="uk-card-body">
                        <slot name="default"></slot>
                    </div>

                </card>
            </slot>

        </div>
    </block>



</template>

<script>
    import {computed, watchEffect} from 'vue'
    import { useMeta } from 'vue-meta'
    import {useI18n} from "vue-i18n/index";

    export default {
        setup () {
            let {t} = useI18n();
            let {meta} = useMeta( {
                'sitename': {tag: 'meta', 'name':'sitename', content: t('core.meta.adminSiteName')},
            });


            return {meta};
        },
        props: {
            showTitleBackLink : {
                type: Boolean,
                default: true,
            },
            showToolbar : {
                type: Boolean,
                default: true
            },
            backRoute: {
                type: [Object, Boolean],
                default: false,
            }
        },
        data: function () {
            return {

            };
        },
        computed: {

        },
        methods: {
            goBack() {
                if (this.backRoute) {
                    this.$router.push(this.backRoute);
                } else {
                    this.$router.back();
                }
            }
        },
        mounted () {


        }

    }
</script>


<style scoped lang="scss">
    .main-content-wrapper {
        padding: 25px 40px;
    }

    .admin-view-toolbar {
        padding-top: calc(var(--global-margin) * 2);
        padding-left: calc(var(--global-margin) * 2);
        padding-right: calc(var(--global-margin) * 2);
    }

  .title-back-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.5em;
      width: 1.5em;
  }


</style>
