<template>
    <div :class="wrapperClass">
        <slot></slot>
    </div>
</template>

<script>
import ButtonGroupMixin from '@/client/extensions/mixins/baseComponents/html/buttonGroup.js'

    export default {
        mixins: [ButtonGroupMixin],
        props: {

        },
        computed: {
            wrapperClass() {
                let result = ['flex', 'gap-s', 'button-group', 's-button-group'];

                if (this.orientation === 'vertical') {
                    result.push('flex-column');
                    result.push('s-button-group--vertical');
                } else {
                    result.push('s-button-group--horizontal');
                }

                if (this.tight) {
                    result.push('s-button-group--tight');
                }

                return result;
            }
        }
    }
</script>
<style  lang="scss">
    .s-button-group {
        &--vertical {
            &.flex-wrap {
                .button {
                   // margin-block-end:  calc(var(--global-margin) * 0.3)!important;

                    &:last-of-type {
                        margin-block-end: 0;
                    }
                }
            }
        }

        &--horizontal {
            .button {
            //    margin-inline-end:  calc(var(--global-margin) * 0.3)!important;

                &:last-of-type {
                    margin-inline-end: 0;
                }
            }

            &.flex-wrap {
                .uk-button {
                  //  margin-block-end:  calc(var(--global-margin) * 0.3)!important;

                    &:last-of-type {
                        margin-block-end: 0;
                    }
                }
            }
        }

        &--vertical.s-button-group--tight {
            .button {
                margin-block-end:  0px!important;

                &:last-of-type {
                    margin-block-end: 0px;
                }
            }

            &.uk-flex-wrap {
                .button {
                    margin-block-end:  0px!important;

                    &:last-of-type {
                        margin-block-end: 0px;
                    }
                }
            }
        }

        &--horizontal.s-button-group--tight {
            .button {
                margin-inline-end:  0px!important;

                &:last-of-type {
                    margin-inline-end: 0px;
                }
            }

            &.uk-flex-wrap {
                .button {
                    margin-block-end:  0px!important;

                    &:last-of-type {
                        margin-block-end: 0px;
                    }
                }
            }
        }



    }
</style>
