let conf = {...config.inactivityDisconnection};

export default {
    
    props: {
        isEnabled: {
            type: Boolean,
            default: conf.isEnabled,
        },
        /**
         * At what interval should we inspect activity status
         */
        tickInterval: {
            type: Number,
            default: conf.defaultTickInterval
        },
        /**
         * How long should the notification about an impending disconnection appear
         * The notification will show this long before the disconnection happens
         */
        notificationDuration : {
            type: Number,
            default: conf.defaultNotificationDuration // 5 minutes
        },
        /**
         * Total time of inactivity before disconnection. Tickspeed variation may change this a little
         * maximum actual time under all circumstances is maxInactivity+tickInterval.
         */
        maxInactivity: {
            type: Number,
            default: conf.defaultMaxInactivity // 15 min
        },
        /**
         * Redirection on disconnect approval, can be router object or false (default redirect)
         */
        redirect: {
            type: [Object, Boolean],
            default: false
        },
        /**
         * As this component only applies to connected users, this function determines
         * if a user is connected or not
         */
        userConnectedCondition : {
            type: Function,
            default: function () {
                return this.$store.getters['user/isAuthenticated'];
            }
        },
        /**
         * This function handles the actual disconnection of the user
         */
        logoutHandler: {
            type: Function,
            default: function (instance) {
                this.$store.commit('user/logout');
            }
        },
        /**
         * This function controls what happens when the user approves their disconnection
         */
        disconnectApproveHandler: {
            type: Function,
            default: function (instance) {
                if ( ! window) {
                    return;
                }
                
                if ( ! this.redirect) {
                    window.location.reload();
                } else {
                    this.$router.push(this.redirect);
                }
            }
        },
    },
    data: function () {
        return {
            killed: false,
            checkTimeout : null,
            isInactive : null,
            inactiveSince: Math.floor(Date.now() / 1000),
            interval: null,
            activityEvents: ['mousemove','scroll','keydown','click','touchstart','wheel'],
            showInactivityModal: false,
            showDisconnectedModal: false,
        };
    },
    computed: {
    
    },
    methods: {
        watchActivity() {
            if ( ! window) { // extra safe for SSR
                return;
            }
            this.listenForActivity();
            if (utilities.isClient()) {
                this.interval = setInterval(this.tick, this.tickInterval*1000);
            }
           
        },
        stopWatchingActivity() {
            if ( ! window) { // extra safe for SSR
                return;
            }
            this.stopListeningForActivity();
            clearInterval(this.interval);
        },
        tick() {
            if ( ! this.userConnectedCondition()) {
                return true;
            }
            
            if ( ! window) { // extra safe for SSR
                return;
            }
            
            if (this.killed) {
                return;
            }
            
            let inactivityTime = Math.floor(Date.now() / 1000) - this.inactiveSince;
            
            if (inactivityTime >= this.maxInactivity - this.notificationDuration) {
                this.isInactive = true;
                this.showInactivityModal = true;
            }
            
            if (inactivityTime >= this.maxInactivity) {
                this.showInactivityModal = false;
                this.showDisconnectedModal = true;
                this.logout();
                return;
            }
        },
        logout () {
            this.logoutHandler(this);
        },
        registerActivity () {
            this.inactiveSince = Math.floor(Date.now() / 1000);
            this.isInactive = false;
        },
        listenForActivity () {
            if ( ! window) { // extra safe for SSR
                return;
            }
            
            this.activityEvents.forEach( name => {
                document.body.addEventListener(name, this.registerActivity)
            });
        },
        stopListeningForActivity () {
            if ( ! window) { // extra safe for SSR
                return;
            }
            this.activityEvents.forEach( name => {
                document.body.removeEventListener(name, this.registerActivity)
            });
        },
        stayActiveRequested () {
            this.showInactivityModal = false;
            this.registerActivity();
        },
        disconnectApproveHandlerInternal () {
            return this.disconnectApproveHandler(this);
        }
    },
    mounted () {
        if (this.isEnabled) {
            this.watchActivity();
        }
        
    },
    beforeUnmount() {
        if (this.isEnabled) {
            this.stopWatchingActivity();
        }
        
    }
    
}
