<template>
    <fieldset class="form__fieldset ">

        <slot name="title">
            <h4 class="form__fieldset-title flex flex-top " v-if="title">
                <icon :icon="icon" v-if="icon" ></icon>
                {{translateTitleCase(title)}}
            </h4>
        </slot>

       <div class="form__fieldset-content" :class="contentClass" v-responsive-classes>
           <slot></slot>
       </div>
    </fieldset>
</template>

<script>
import formFieldSetMixin from '@/client/extensions/mixins/baseComponents/form/formFieldSet.js'

export default {
    mixins: [formFieldSetMixin],
    props: {
        maxFieldsPerRow: {
            type: [Number, String],
            default: 1
        }
    },
    data: function () {
        return {};
    },
    computed: {
        contentClass() {
            let result = [];

            if (+this.maxFieldsPerRow > 1) {
                result.push('max-'+this.maxFieldsPerRow+'-per-row')
            }
            return result;
        }
    }

}
</script>

<style scoped lang="scss">

</style>
