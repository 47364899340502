<template>


    <icon class="field__icon field__icon--end"
          @click="clear"
          v-if="modelValue"
          icon="x"
          :size="1.2"
          :theme="'gray'"
          style="cursor: pointer;"/>
    <timePicker  :type="computedInputType"
                :hideInputIcon="true"
                :auto-apply="true"
                 ref="timePicker"
                 timePicker
                 v-bind="{...inputAttrs, 'class': ''}"
                 v-model="value"
                 :clearable="false"
                 :inputClassName="$props.class"

    />

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    props: {
        class : {},
        modelValue    : {},
        type: {},
        list: {} // prevent inheritence of list="" on element
    },
    data: function () {
        return {
            isFocused: false,
            test: null
        };
    },
    emits: ['update:modelValue'],
    components: { // load timePicker async
        timePicker :  defineAsyncComponent(() => import('vue3-date-time-picker'))
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        inputAttrs() {
            let attrs = {...this.$attrs};
            delete attrs.list;

            delete attrs.class;
            return attrs;
        },
        computedInputType () {
            return this.isFocused ? 'date' : 'text'
        }
    },
    created() { // load our styles async
        let style = import('vue3-date-time-picker/dist/main.css');
    },
    methods: {
        clear () {
            this.$refs.timePicker.clearValue();
        }
    }

}

</script>

<style scoped lang="scss">

</style>


