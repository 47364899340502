/**
 * Async method to obtain a uiFramework's ui adapter
 * @returns {Promise<*>}
 */
let getUiFrameworkAdapterAsync = async () => {
    let module = await utilities.customImportWithAppSource(`assets/frameworks/${config.style.frameworks[0]}/saffronAdapter.js`);
    return module.default || module;
};

/**
 * Method to assign and lazy create $saffron on a Vue instance
 * @param instance
 */
let lazyAssignSaffron = (instance) => {
    if (typeof instance.$saffron === 'object') {
        return;
    }
    
    instance.$saffron = {};
    
    // grab the $s namespace if desired
    if (config.useNamespaceS) {
        instance.$s = instance.$saffron;
    }
};


let getMockGlobalSpinnerAdapter = () => {
    return {
        show() {return false;},
        hide() {return false;},
        isActive() {return null;},
    };
}
/**
 * Method to ass a UI object to $saffron
 * @param uiAdapter
 * @param instance
 * @param $saffron
 */
let addUiToSaffron = (uiAdapter, instance, $saffron) => {
    if (typeof $saffron.ui === 'undefined' || ! $saffron.ui || typeof $saffron.ui !== 'object') {
        $saffron.ui = {};
    }
    
    //TODO: we are making active use of $saffron.ui.adapter.dropdown in select2 (uikit)
    $saffron.ui.adapter      = $saffron.ui.adapter || uiAdapter;
    $saffron.ui.modal        = uiAdapter.getModalAdapter(instance);
    $saffron.ui.notification = uiAdapter.getNotificationAdapter(instance);
    $saffron.ui.dropdown     = uiAdapter.getDropdownAdapter(instance);
  
    // not all adapters are compatible in all style frameworks
    if (uiAdapter.getGlobalSpinnerAdapter) {
        $saffron.ui.globalSpinner   = uiAdapter.getGlobalSpinnerAdapter(instance);
    } else {
        $saffron.ui.globalSpinner = getMockGlobalSpinnerAdapter(instance);
    }
};

/**
 * Method to add $saffron to instance. May also assign by reference into $s, if configured to do so
 * @param uiAdapter
 * @param instance
 */
let addSaffronToInstance = (uiAdapter, instance) => {
    lazyAssignSaffron(instance);
    addUiToSaffron(uiAdapter, instance, instance.$saffron);
};


export default new Promise(async (resolve) => {
    let uiAdapter = await getUiFrameworkAdapterAsync();
    
    let $saffronComponent = {};
    
    resolve({
        install(app) {
            app.mixin({
                created() {
                    addSaffronToInstance(uiAdapter, this);
                },
                computed : {
                    $saffronComponent () {
                       return $saffronComponent
                    }
                },
                methods: {
                    setSaffronComponent(val) {
                        $saffronComponent = val;
                    }
                }
          });
        },
    })
});


